import React from "react";
import { Container, CanvasSign, CanvasContainer, CheckboxContainer } from "./index.style";
import { Checkbox, Row, Button, Col, Form, message } from "antd";
import CanvasDraw from "react-canvas-draw";
import { RuleObject } from "antd/lib/form";
import { base64ToBlob } from "../../../../functions/files";

const styleCanvas = {
    width: 380,
    height: 260,
    brushRadius: 1,
    lazyRadius: 1,
};
interface drawObj {
    lines: object[];
    width: string;
    height: string;
}

type SignContractProps = {
    prevStep: () => void;
    nextStep: () => void;
    boothForm: any;
    tenantForm: any;
    onSubmitApplication: (firstSignature: Blob | undefined) => void;
    typeForm: string;
    getBoothName: (boothCode: string) => string;
};

const SignContract: React.FC<SignContractProps> = ({
    prevStep,
    nextStep,
    boothForm,
    tenantForm,
    onSubmitApplication,
    getBoothName,
}) => {
    const { width, height, brushRadius, lazyRadius } = styleCanvas;
    const signatureFirstRef = React.useRef<CanvasDraw>(null);
    const signatureSecondRef = React.useRef<CanvasDraw>(null);

    const onFinish = async () => {
        const firstSignature = await exportToBlob(signatureFirstRef);
        const secondSignature = await exportToBlob(signatureSecondRef);
        if (firstSignature === "not draw next step" || secondSignature === "not draw next step")
            return message.error("กรุณาเซ็นลายเซ็น");
        try {
            await await onSubmitApplication(firstSignature);
            nextStep();
        } catch (error) {
            console.log({ error });
            return message.error("มีบางอย่างผิดพลาด");
        }
    };

    const exportToBlob = async (canvasRef: object | any) => {
        if (!canvasRef.current) return;
        if (isDraw(canvasRef)) {
            const base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL();
            const image = await base64ToBlob(base64 as string);
            return image;
        } else return "not draw next step";
    };

    const isDraw = (canvasRef: object | any): boolean => {
        const draw: any = canvasRef.current?.getSaveData();
        const drawObj: drawObj = JSON.parse(draw);
        if (drawObj.lines.length === 0) return false;
        else return true;
    };

    const [form] = Form.useForm();

    return (
        <Container>
            <Form form={form} onFinish={onFinish}>
                <h2>ลงลายมือชื่อ</h2>
                <h4>
                    คุณ&nbsp;<b>{tenantForm?.firstName0 || "ชื่อผู้เช่าแผง"}</b>&nbsp;
                    <b>{tenantForm?.lastName0 || "นามสกุลผู้เช่าแผง"}</b>&nbsp;
                    เช่าพื้นที่อาคาร&nbsp;
                    <b>{boothForm?.building || "-"}</b>&nbsp;แผงที่&nbsp;
                    {boothForm?.booth.map((element: string) => {
                        return <b key={element}>{getBoothName(element)} </b>;
                    }) || "-"}
                    &nbsp;
                </h4>
                <p>
                    เริ่มสัญญาวันที่&nbsp;<b>{boothForm?.date[0].format("YYYY-MM-DD") || "-"}</b>
                    &nbsp;ถึงวันที่&nbsp;<b>{boothForm?.date[1].format("YYYY-MM-DD") || "-"}</b>
                </p>

                <Row justify="space-between" align="middle" gutter={[16, 24]}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <CanvasContainer>
                            <div>
                                <p>ลงลายมือชื่อผู้เช่าครั้งที่ 1</p>
                                <CanvasSign
                                    saveData="saveData"
                                    canvasWidth={width}
                                    canvasHeight={height}
                                    brushRadius={brushRadius}
                                    lazyRadius={lazyRadius}
                                    hideGrid
                                    hideInterface
                                    ref={signatureFirstRef}
                                />
                            </div>
                        </CanvasContainer>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <CanvasContainer>
                            <div>
                                <p>ลงลายมือชื่อผู้เช่าครั้งที่ 2</p>
                                <CanvasSign
                                    canvasWidth={width}
                                    canvasHeight={height}
                                    brushRadius={brushRadius}
                                    lazyRadius={lazyRadius}
                                    hideGrid
                                    hideInterface
                                    ref={signatureSecondRef}
                                />
                            </div>
                        </CanvasContainer>
                    </Col>
                </Row>

                <CheckboxContainer>
                    <CheckboxForm
                        label=" ข้าพเจ้าขอรับรองว่าลายมือชื่อนี้เป็นตัวข้าพเจ้าจริง
                            สามารถนำไปใช้และนำไปดำเนินคดีตามกฏหมายได้"
                    />
                    <CheckboxForm label=" ข้าพเจ้ายอมรับข้อตกลงต่างๆ และเงื่อนไขของตลาดศรีเมืองทองทั้งหมด" />
                </CheckboxContainer>
                <Row justify="space-between">
                    <Button onClick={prevStep}>ย้อนกลับ</Button>
                    <Button type="primary" htmlType="submit">
                        ถัดไป
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

const CheckboxForm = (props: any) => {
    const [checked, setChecked] = React.useState(false);

    const onCheckboxChange = async (e: any) => {
        await setChecked(e.target.checked);
    };

    const validation = (_: RuleObject, __: any, callback: (error?: string) => void) => {
        if (checked) return callback();
        return callback("กรุณาเลือกเพื่อรับทราบข้อตกลง");
    };
    return (
        <Form.Item name="checkbox" rules={[{ validator: validation }]}>
            <Checkbox checked={checked} onChange={onCheckboxChange}>
                {props.label}
            </Checkbox>
        </Form.Item>
    );
};

export default SignContract;
