import React from "react";
import { Avatar, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { MemberType } from "../../../../interfaces/MemberManagement";
import { Container, EditButton } from "./index.style";
import colors from "../../../../assets/colors.json";
import { useMemberDetailContext } from "../../../../pages/MemberManagement/Staff/MemberDetail/useMemberDetail";
import { rolesEnToTh } from "../../../../assets/roles";

function CardMember(props: MemberType) {
    const { setMemberUpdate, setOpenModalUpdate } = useMemberDetailContext();

    const onClickEdit = () => {
        setMemberUpdate(props);
        setOpenModalUpdate(true);
    };

    return (
        <Container>
            <EditButton onClick={onClickEdit} />
            <Avatar size={96} icon={<UserOutlined />} src={props.urlImage} />
            <h3>{props.name}</h3>
            <p>{props.email}</p>
            <div>
                {props.mainDuty ? (
                    <Tag
                        style={{ color: colors.textColor }}
                        color={colors.memberDetailWorkColor?.mainDuty}
                        children={rolesEnToTh[props.mainDuty] || props.mainDuty}
                    />
                ) : null}
                {props.subDuty &&
                    props.subDuty.map((item, k) => (
                        <Tag
                            style={{ color: colors.textColor }}
                            key={`subDuty-${k}-${props.name}`}
                            color={colors.memberDetailWorkColor.subDuty}
                            children={rolesEnToTh[item] || item}
                        />
                    ))}
                {props.dutyPool &&
                    props.dutyPool.map((item, k) => (
                        <Tag
                            style={{ color: colors.textColor }}
                            key={`subDuty-${k}-${props.name}`}
                            color={colors.memberDetailWorkColor.dutyPool}
                            children={rolesEnToTh[item] || item}
                        />
                    ))}
            </div>
        </Container>
    );
}

export default CardMember;
