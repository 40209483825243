import { IBooth, ILabor, ILomsak, IRider, IVeggie } from "../../../../../interfaces/Contract";
import { FormInstanceType } from "../../ModalComponents/FormContent";
import { IContractResponse } from "../useContractTable";

export const getIncludes = (
    searchKey: string,
    type: FormInstanceType,
    item: IContractResponse,
    date: string
) => {
    if (type === "rider") {
        const data = item as IRider;
        return (
            `${data.firstName} ${data.lastName}`.includes(searchKey) ||
            data.tel.includes(searchKey) ||
            date.includes(searchKey)
        );
    } else if (type === "labor" || type === "member") {
        const data = item as ILabor;
        return (
            `${data.firstName} ${data.lastName}`.includes(searchKey) ||
            data.tel.includes(searchKey) ||
            data.booth.includes(searchKey) ||
            date.includes(searchKey)
        );
    } else if (type === "lomsak") {
        const data = item as ILomsak;
        return (
            `${data.firstName} ${data.lastName}`.includes(searchKey) ||
            data.tel.includes(searchKey) ||
            data.size.includes(searchKey) ||
            data.booth.includes(searchKey) ||
            date.includes(searchKey)
        );
    }
    const data = item as IVeggie | IBooth;
    return (
        `${data.firstName} ${data.lastName}`.includes(searchKey) ||
        data.tel.includes(searchKey) ||
        data.booth.join(" ").includes(searchKey) ||
        date.includes(searchKey)
    );
};
