import styled from "styled-components";
import colors from "../../assets/colors.json";
import { Typography } from "antd";

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: ${colors.background};
`;

export const LogoContainer = styled.div`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 235px;
  height: 235px;
  position: absolute;
  top: -25%;
  background-color: rgba(81, 167, 40, 0.5);
  @media screen and (max-width: 768px) {
    top: -35%;
  }
`;

export const LogoImage = styled.img`
  width: 220px;
  height: 140px;
`;
export const BoxContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 0.5rem;
  width: 400px;
  height: 440px;
  border: 1px solid ${colors.hintText};
  background-color: #fff;

  @media screen and (max-width: 768px) {
    width: 360px;
    height: 400px;
    margin-top: 2rem;
  }
`;

export const Title = styled(Typography.Title)`
  margin: 0 !important;
`;

export const BottomBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5%;
`;

export const SubTitle = styled(Typography.Text)`
  margin: 0 !important;
  font-size: 1.2rem;
`;

export const Text = styled(Typography.Text)`
  margin: 0 !important;
`;
