import dayjs from "dayjs";
import Swal from "sweetalert2";
import colors from "../../../assets/colors.json";

const convertKeyToWord = (key: string) => {
    const item: { [key: string]: string } = {
        tel: "เบอร์โทรศัพท์",
        firstName: "ชื่อจริง",
        lastName: "นามสกุล",
    };
    return item[key];
};

export const deleteAlert = async (record: any) => {
    const res = await Swal.fire({
        icon: "warning",
        html: `<h2>คุณต้องการลบสัญญาของคุณ ${record.firstName} ${record.lastName} ใช่หรือไม่?</h2>`,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
        reverseButtons: true,
        confirmButtonColor: colors.primary,
        showCancelButton: true,
    });
    return res.value;
};

export const successAlert = async () => {
    await Swal.fire({
        icon: "success",
        title: `ดำเนินการเสร็จสิ้น`,
        showConfirmButton: false,
        timer: 1500,
    });
};

export const infoAlert = async () => {
    const result = await Swal.fire({
        icon: "info",
        html: `<h2>ไม่มีข้อมูลที่ถูกแก้ไข</h2>`,
        showConfirmButton: false,
        timer: 1500,
    });
    return result.value;
};

export const exitOnEditAlert = async () => {
    const result = await Swal.fire({
        icon: "warning",
        html: `<h2>หากออกตอนนี้ข้อมูลจะยังไม่ถูกแก้ไข <br /> ต้องการจะออกจากหน้านี้ใช่หรือไม่</h2>`,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
        reverseButtons: true,
        confirmButtonColor: colors.primary,
        showCancelButton: true,
    });
    return result.value;
}

export const editAlert = async (data: any) => {
    let text = "";
    Object.keys(data).forEach((key) => {
        if (key === "vaccine") {
            let vac = "";
            data[key].forEach((item: any) => {
                vac += `แก้ไข/เพิ่ม วันที่ฉีดวัคซีน ${dayjs(item.date).format("DD/MM/YYYY")} ชนิด ${
                    item.name
                } <br />`;
            });
            text += vac;
        } else if (key === "involved") {
            let involved = "";
            Object.keys(data[key]).forEach((innerkey) => {
                involved += `${convertKeyToWord(innerkey)}ผู้เกี่ยวข้อง เป็น ${
                    data[key][innerkey]
                } <br />`;
            });
            text += involved;
        } else {
            text += `${convertKeyToWord(key)} เป็น ${data[key]} <br />`;
        }
    });

    if (text === "") return infoAlert();

    const result = await Swal.fire({
        icon: "warning",
        html: `<h3>คุณต้องการแก้ไขข้อมูลเหล่านี้ใช่หรือไม่?</h3> ${text}`,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
        reverseButtons: true,
        confirmButtonColor: colors.primary,
        showCancelButton: true,
    });
    return result.value;
};

export const exportAlert = async () => {
    await Swal.fire({
        icon: "success",
        html: `<h2>กำลังดำเนินการเตรียมไฟล์</h2>`,
        showConfirmButton: false,
        timer: 1500,
    });
};