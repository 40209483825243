import { PageHeader, Collapse, Form, Spin } from "antd";
import { FormShirtRenderer } from "../../../components/PriceManagementPage/ShirtManagement/FormShirtRendererArray";
import { Container, ContainerSpinner } from "../index.styles";
import RenderPanelData from "../../../components/PriceManagementPage/RenderPanelData";
import { Renderer } from "../../../interfaces/PriceManagement";
import useShirtManagement from "./useShirtManagement";

function ShirtManagement() {
    const { data, form, onResetForm, onFinish, isLoading } = useShirtManagement();

    const onRendererPanel = FormShirtRenderer.map((item: Renderer, index: number) => (
        <Collapse.Panel header={item.header} key={index}>
            <RenderPanelData component={item.component} cancel={onResetForm} />
        </Collapse.Panel>
    ));

    return (
        <Container>
            <PageHeader title="จัดการราคาเสื้อแผง เสื้อวิน เสื้อขึ้นของ ที่จอดรถและรถหล่มรายเดือน" />
            {isLoading ? (
                <ContainerSpinner>
                    <Spin tip="กำลังรีเฟรชข้อมูล..." />
                </ContainerSpinner>
            ) : (
                <Form initialValues={data} form={form} onFinish={onFinish} colon={false}>
                    <Collapse accordion onChange={onResetForm}>
                        {onRendererPanel}
                    </Collapse>
                </Form>
            )}
        </Container>
    );
}

export default ShirtManagement;
