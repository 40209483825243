import { Button, Form, Row, Select } from "antd";
import React from "react";
import { DatePicker } from "antd";
import { Container, RowGroupInput } from "../../../common/CustomForm";
import NumberInput from "../../../common/NumberInput";
import TextArea from "antd/lib/input/TextArea";
import useRegister from "../../../../pages/Register/useRegister";
const { RangePicker } = DatePicker;
const { Option } = Select;

const BoothForm: React.FC<ReturnType<typeof useRegister>> = ({
    buildingStorage,
    prevStep,
    setBoothForm,
    boothForm,
    nextStep,
}) => {
    const [buildingSelect, setBuildingSelect] = React.useState<string | undefined>(
        boothForm?.building
    );
    const [numCar, setNumCar] = React.useState<number>(1);
    const [form] = Form.useForm();
    const onSubmit = (values: any) => {
        setBoothForm(values);
        nextStep();
    };
    React.useEffect(() => {
        form.setFieldsValue({
            booth: [],
        });
    }, [buildingSelect, form]);

    return (
        <Container>
            <Form form={form} onFinish={onSubmit} initialValues={boothForm}>
                <Row justify="center">
                    <h2>ข้อมูลแผงรายเดือน</h2>
                </Row>
                <b>ข้อมูลแผงที่เช่า</b>
                <RowGroupInput
                    inputs={[
                        {
                            name: "building",
                            label: "อาคาร",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกอาคาร",
                                },
                            ],
                            customInput: (
                                <Select onChange={setBuildingSelect}>
                                    {Object.keys(buildingStorage).map((item) => (
                                        <Option children={item} key={item} />
                                    ))}
                                </Select>
                            ),
                        },
                        {
                            name: "booth",
                            label: "เลขแผง",
                            flex: 2,
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกเลขแผง",
                                },
                            ],
                            customInput: (
                                <Select
                                    mode="multiple"
                                    notFoundContent={!buildingSelect && "กรุณาเลือกอาคาร"}
                                >
                                    {buildingSelect &&
                                        buildingStorage[buildingSelect].map((item) => (
                                            <Option
                                                children={item.boothName}
                                                key={item.boothCode}
                                                value={item.boothCode}
                                            />
                                        ))}
                                </Select>
                            ),
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "date",
                            label: "วันเริ่มสัญญา",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณาเลือกวันเริ่ม/สิ้นสุด สัญญา",
                                },
                            ],
                            customInput: (
                                <RangePicker
                                    style={{ width: "80%", margin: "0 auto" }}
                                    placeholder={["วันที่เริ่ม", "วันที่สิ้นสุด"]}
                                />
                            ),
                            flex: 1.5,
                        },
                        {
                            name: "numOfParking",
                            label: "จำนวนที่จอดรถ",
                            customInput: (
                                <NumberInput
                                    initialValue={numCar}
                                    onChange={setNumCar}
                                    minValue={0}
                                />
                            ),
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "memo",
                            label: "หมายเหตุ",
                            customInput: <TextArea rows={4} />,
                        },
                    ]}
                />
                {/* Footer */}
                <Row justify="space-between">
                    <Button onClick={prevStep}>ย้อนกลับ</Button>
                    <Button type="primary" htmlType="submit">
                        ถัดไป
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default BoothForm;
