import React from "react";
import liff from "@line/liff";
import { postRegisterLine } from "../../apis/registerLine";
import { Spin } from "antd";
import styled from "styled-components";

export const ContainerSpin = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RegisterLine = () => {
    const [idToken, setIdToken] = React.useState<string | null>("");
    const [boothNumber, setBoothNumber] = React.useState<string>("z1a1#1");

    const LineLoginGetToken = async () => {
        await liff.init({ liffId: `1657032114-4MEKoWzp` });
        if (liff.isLoggedIn()) {
            setIdToken(liff.getAccessToken());
            setBoothNumber("z1a1#1");
        } else {
            liff.login();
        }
    };

    const onRegisterTokenLine = React.useCallback(
        async (boothNumber: string, idToken: string | null) => {
            try {
                await postRegisterLine(boothNumber, idToken);
            } catch (error) {
                console.log("error", error);
            }
        },
        []
    );

    React.useEffect(() => {
        LineLoginGetToken();
        onRegisterTokenLine(boothNumber, idToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idToken]);

    return (
        <ContainerSpin>
            <Spin />
        </ContainerSpin>
    );
};

export default RegisterLine;
