import MonthlyBoothManagement from "./MonthlyBoothManagement";
import VeggieBoothManagement from "./VeggieBoothManagement";
import ShirtManagement from "./ShirtManagement";
import MeterManagement from "./MeterManagement";
import meterIcon from "../../assets/icons/meter.png";
import shopIcon from "../../assets/icons/shop.png";
import shirtIcon from "../../assets/icons/shirt.png";
import veggieBoothIcon from "../../assets/icons/veggieBooth.png";
import { SubRouteProps } from "../../interfaces/PriceManagement";

export const PriceManagementSubRoute: SubRouteProps[] = [
  {
    title: "จัดการแผงรายเดือน",
    icon: shopIcon,
    value: "monthlyBoothManagement",
    component: <MonthlyBoothManagement />,
  },
  {
    title: "จัดการแผงผักบ้าน",
    icon: veggieBoothIcon,
    value: "veggieBoothManagement",
    component: <VeggieBoothManagement />,
  },
  {
    title: "จัดการเสื้อ",
    icon: shirtIcon,
    value: "shirtManagement",
    component: <ShirtManagement />,
  },
  {
    title: "จัดการหน่วยมิเตอร์",
    icon: meterIcon,
    value: "meterManagement",
    component: <MeterManagement />,
  },
];
