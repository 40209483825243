import { Row } from "antd";

const SymbolStatus = () => {
    const symbol = [
        { label: "มีผู้เช่า", color: "#51A728" },
        { label: "ติดจอง", color: "#DF773A" },
        { label: "ว่าง", color: "#ECF034" },
    ];

    return (
        <Row>
            {symbol.map(({ label, color }) => (
                <Row align="middle">
                    <div
                        style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            backgroundColor: color,
                            margin: "0 0.5rem",
                        }}
                    ></div>
                    {label}
                </Row>
            ))}
        </Row>
    );
};

export default SymbolStatus;
