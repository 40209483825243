import { Avatar, List } from "antd";
import styled from "styled-components";

export const MetaList = styled(List.Item.Meta)`
    display: flex;
    align-items: center;

`;

export const AvatarLogo = styled(Avatar)`
    background-color: #fff;
`;

export const ContainerMeta = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const ListItem = styled(List.Item)`
    display: flex;
    align-items: center;
    width: 100%;
`;
