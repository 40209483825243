import GoogleLoginIcon from "../../../assets/icons/GoogleLoginIcon";
import { Container, Title } from "./index.styles";

type IProps = {
  handleLogin: () => void;
};

export default function GoogleLogin(props: IProps) {
  const { handleLogin } = props;
  return (
    <Container onClick={handleLogin}>
      <GoogleLoginIcon />
      <Title level={4}>ดำเนินการต่อด้วย Google</Title>
    </Container>
  );
}
