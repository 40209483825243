import React from "react";
import { Collapse, List, PageHeader, Typography } from "antd";
import { CircleState, CollapseFullWidth, Container, SubComponent, SubRoute } from "./index.styles";
import CardManagement from "../../components/PriceManagementPage/CardManagement";
import { PriceManagementSubRoute } from "./subRouter";
import { SubRouteProps } from "../../interfaces/PriceManagement";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import HeadPanelRoute from "../../components/PriceManagementPage/HeadPanelRoute";
import {
    AvatarLogo,
    MetaList,
} from "../../components/PriceManagementPage/HeadPanelRoute/index.styles";
function PriceManagement() {
    const [subRoute, setSubRoute] = React.useState<SubRouteProps>(PriceManagementSubRoute[0]);

    const { lg } = useBreakpoint();

    const classNameSubRoute = (sRoute: SubRouteProps) =>
        sRoute.value === subRoute.value ? "active" : "";

    const onChangeSubRoute = (sRoute: SubRouteProps) => () => setSubRoute(sRoute);

    const onChangeSubRouteInput = (value: string) => () => {
        const sRoute = PriceManagementSubRoute.findIndex((i) => i.value === value);
        setSubRoute(PriceManagementSubRoute[sRoute]);
    };

    return (
        <Container>
            <PageHeader title="จัดการราคา" />
            <SubRoute>
                {lg ? (
                    <>
                        {PriceManagementSubRoute.map((sRoute) => (
                            <CardManagement
                                key={sRoute.value}
                                icon={sRoute.icon}
                                title={sRoute.title}
                                active={classNameSubRoute(sRoute)}
                                onClick={onChangeSubRoute(sRoute)}
                            />
                        ))}
                    </>
                ) : (
                    <CollapseFullWidth>
                        <Collapse.Panel
                            showArrow={false}
                            header={<HeadPanelRoute subRoute={subRoute} />}
                            key={1}
                        >
                            <List
                                dataSource={PriceManagementSubRoute}
                                renderItem={(sRoute) => (
                                    <List.Item onClick={onChangeSubRouteInput(sRoute.value)}>
                                        <MetaList
                                            avatar={<AvatarLogo size={50} src={sRoute.icon} />}
                                            title={<Typography>{sRoute.title}</Typography>}
                                        />
                                        <CircleState state={classNameSubRoute(sRoute)} />
                                    </List.Item>
                                )}
                            />
                        </Collapse.Panel>
                    </CollapseFullWidth>
                )}
            </SubRoute>
            <SubComponent>{subRoute.component}</SubComponent>
        </Container>
    );
}

export default PriceManagement;
