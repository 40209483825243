import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(81, 167, 40, 0.2);
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
`;
