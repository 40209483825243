import { Button, Form, Timeline } from "antd";
import styled from "styled-components";

export const TimelineContainer = styled(Timeline)`
    margin-top: 0.5rem;
    margin-left: 1.5rem;
`;

export const TimelineItem = styled(Timeline.Item)`
    & div.ant-timeline-item-head {
        margin-top: -2px;
    }
    &.ant-timeline-item.ant-timeline-item-last {
        padding-bottom: 0rem !important;

        div.ant-timeline-item-content {
            min-height: 0 !important;
        }
    }
`;

export const VaccineContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

export const RowContainer = styled.div`
    display: flex;
    gap: 1.5rem;
`;

export const RowInputContainer = styled.div`
    display: flex;
    gap: 1rem;
`;
export const ButtonAdd = styled(Button)`
    margin-top: -0.2rem;
`;

export const FormItem = styled(Form.Item)`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    & .ant-form-item-label {
        width: fit-content;
        > label {
            &::after {
                content: "";
            }
        }
    }
    & .ant-form-item-required {
        &::before {
            content: "" !important;
        }
        &::after {
            content: "*" !important;
            color: red;
            margin-left: 0.25rem;
        }
    }
    & .ant-input {
        border-radius: 4px;
        overflow: hidden;
    }
`;
