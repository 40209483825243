import { SVGProps, memo } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.03125 15.0001C7.03125 13.5137 7.44076 12.1214 8.15221 10.9292V5.87207H3.0951C1.08797 8.47879 0 11.6557 0 15.0001C0 18.3445 1.08797 21.5214 3.0951 24.1281H8.15221V19.071C7.44076 17.8788 7.03125 16.4864 7.03125 15.0001V15.0001Z"
      fill="#FBBD00"
    />
    <path
      d="M15 22.9686L11.4844 26.4842L15 29.9998C18.3445 29.9998 21.5213 28.9119 24.128 26.9047V21.853H19.0763C17.8737 22.5671 16.4754 22.9686 15 22.9686V22.9686Z"
      fill="#0F9D58"
    />
    <path
      d="M8.15184 19.0708L3.09473 24.1279C3.49211 24.644 3.92482 25.1382 4.39305 25.6065C7.22617 28.4396 10.993 29.9999 14.9996 29.9999V22.9686C12.092 22.9686 9.54356 21.403 8.15184 19.0708Z"
      fill="#31AA52"
    />
    <path
      d="M30 15.0001C30 14.0875 29.9174 13.1732 29.7544 12.2827L29.6225 11.562H15V18.5933H22.1163C21.4253 19.9679 20.3627 21.0895 19.0762 21.8533L24.128 26.905C24.6441 26.5076 25.1383 26.0749 25.6066 25.6067C28.4397 22.7735 30 19.0067 30 15.0001V15.0001Z"
      fill="#3C79E6"
    />
    <path
      d="M20.6348 9.36521L21.2562 9.98666L26.2281 5.01486L25.6066 4.39342C22.7735 1.56029 19.0067 0 15 0L11.4844 3.51562L15 7.03125C17.1285 7.03125 19.1296 7.86012 20.6348 9.36521Z"
      fill="#CF2D48"
    />
    <path
      d="M14.9997 7.03125V0C10.9931 0 7.22623 1.56029 4.39305 4.39336C3.92482 4.86158 3.49211 5.35582 3.09473 5.87197L8.15184 10.9291C9.54362 8.59687 12.092 7.03125 14.9997 7.03125V7.03125Z"
      fill="#EB4132"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
