import dayjs from "dayjs";
import HeadColumn from "./HeadColumn";
import BadgeStatus from "../BadgeStatus";
import { FormInstanceType } from "../ModalComponents/FormContent";

export type ColumnsProps = {
    title: React.ReactNode;
    dataIndex: string | string[];
    render?: (text: string, record: any, index: number) => React.ReactNode;
    renderType: Omit<FormInstanceType, "inner">[];
    filters?: FilterType[];
    onFilter?: (value: string, record: any) => boolean;
    sorter?: (a: any, b: any) => number;
};

type FilterType = {
    text: string;
    value: string;
};

const allTabs: Omit<FormInstanceType, "inner">[] = [
    "booth",
    "veggie",
    "rider",
    "member",
    "labor",
    "lomsak",
    "inner",
];

export const columns: ColumnsProps[] = [
    {
        title: HeadColumn("สถานะ"),
        dataIndex: "status",
        render: (record: any) => <BadgeStatus status={record} size="1rem" />,
        renderType: ["rider", "labor", "lomsak", "inner"],
        filters: [
            {
                text: "ปกติ",
                value: "active",
            },
            {
                text: "พักงาน",
                value: "inactive",
            },
            {
                text: "รักษาสิทธิ์",
                value: "onHold",
            },
        ],
        onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
        title: HeadColumn("วันที่เริ่มทำสัญญา"),
        dataIndex: "startDate",
        render: (record: any) => dayjs(record).format("DD/MM/YYYY"),
        renderType: ["booth", "veggie", "rider", "labor", "lomsak"],
        sorter: (a: any, b: any) => b.startDate - a.startDate,
    },
    {
        title: HeadColumn("ชื่อ - นามสกุล"),
        dataIndex: ["firstName", "lastName"],
        render: (_: string, record: any, __: number) => `${record.firstName} ${record.lastName}`,
        renderType: allTabs,
    },
    {
        title: HeadColumn("เบอร์โทรศัพท์"),
        dataIndex: "tel",
        renderType: allTabs,
    },
    { title: HeadColumn("ประเภทรถ"), dataIndex: "size", renderType: ["lomsak"] },
    {
        title: HeadColumn("สังกัดแผง"),
        dataIndex: "booth",
        render: (_: string, record: any, __: number) => {
            let text = "";
            if (record.booth instanceof Array) {
                text = record.booth.join(" ");
            } else {
                text = record.booth;
            }
            return text;
        },
        renderType: ["booth", "veggie", "labor", "lomsak"],
    },
    {
        title: HeadColumn("เลขเสื้อ"),
        dataIndex: "shirtId",
        renderType: ["labor"],
    },
    {
        title: HeadColumn("เพิ่มเติม"),
        dataIndex: ["contractCode", "memberCode", "firstName", "lastName"],
        renderType: allTabs,
    },
];
