import { FormOneLabel } from "../FormMeterPrice";
import { Renderer } from "../../../../interfaces/PriceManagement";

export const FormMeterRenderer: Renderer[] = [
  {
    header: "มิเตอร์ไฟ",
    component: <FormOneLabel typelabel="electrical" />,
  },
  {
    header: "มิเตอร์นํ้า",
    component: <FormOneLabel typelabel="water" />,
  },
];
