import { Button } from "antd";
import React from "react";
import { FormLayout, ButtonContainer } from "./index.styles";

export type IPanelItem = {
  component: React.ReactNode;
  cancel: () => void;
};

function RenderPanalData(props: IPanelItem) {
  const { component, cancel } = props;
  return (
    <>
      <FormLayout ref={React.createRef<HTMLDivElement>()}>{component}</FormLayout>
      <ButtonContainer>
        <Button onClick={cancel}>ยกเลิก</Button>
        <Button type="primary" htmlType="submit">
          ยืนยันการแก้ไข
        </Button>
      </ButtonContainer>
    </>
  );
}

export default RenderPanalData;
