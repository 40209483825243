import { Typography } from "antd";
import { AvatarLogo, ContainerMeta, ListItem, MetaList } from "./index.styles";
import { DownOutlined } from "@ant-design/icons";
import { SubRouteProps } from "../../../interfaces/PriceManagement";

function HeadPanelRoute(props: { subRoute: SubRouteProps }) {
    const { subRoute } = props;
    const { icon, title } = subRoute;
    return (
        <ListItem>
            <ContainerMeta>
                <MetaList
                    avatar={<AvatarLogo size={50} src={icon} />}
                    title={<Typography>{title}</Typography>}
                />
            </ContainerMeta>
            <DownOutlined style={{ marginLeft: "auto", marginTop: "-0.3rem" }} />
        </ListItem>
    );
}

export default HeadPanelRoute;
