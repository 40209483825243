import { WorkManagementCardType } from "../../../../interfaces/MemberManagement";
import meterIcon from "../../../../assets/icons/meter.png";
// import wallet from "../../../../assets/icons/wallet.png";
import shop from "../../../../assets/icons/shop.png";
import shirt from "../../../../assets/icons/shirt.png";
import parking from "../../../../assets/icons/parking.png";

const mock: WorkManagementCardType[] = [
    {
        title: "จดมิเตอร์ค่าน้ำ/ไฟ",
        icon: meterIcon,
        list: [
            {
                title: "จดมิเตอร์น้ำ",
                menu: [
                    {
                        label: "แผงรายเดือน",
                        value: 0,
                        maxValue: 2,
                        name: "meterBooth",
                    },
                    {
                        label: "แผงผักบ้าน",
                        value: 0,
                        maxValue: 2,
                        name: "meterVeggie",
                    },
                ],
            },
            {
                title: "จดมิเตอร์ไฟ",
                menu: [
                    {
                        label: "แผงรายเดือน",
                        value: 0,
                        maxValue: 3,
                        name: "meterBooth",
                    },
                    {
                        label: "แผงผักบ้าน",
                        value: 0,
                        maxValue: 3,
                        name: "meterVeggie",
                    },
                ],
            },
        ],
    },
    {
        title: "เก็บค่าเช่า",
        icon: shop,
        list: [
            {
                title: "เก็บค่าเช่า",
                menu: [
                    {
                        label: "แผงรายเดือน",
                        value: 0,
                        maxValue: 5,
                        name: "booth",
                    },
                    {
                        label: "แผงผักบ้าน",
                        value: 0,
                        maxValue: 5,
                        name: "veggie",
                    },
                    {
                        label: "รถหล่ม",
                        value: 0,
                        maxValue: 2,
                        name: "lomsak",
                    },
                ],
            },
        ],
    },
    {
        title: "เก็บค่าเสื้อ",
        icon: shirt,
        list: [
            {
                title: "เก็บค่าเสื้อ",
                menu: [
                    {
                        label: "เสื้่อวิน",
                        value: 0,
                        maxValue: 2,
                        name: "riderShirt",
                    },
                    {
                        label: "เสื้่อขึ้นของ",
                        value: 0,
                        maxValue: 3,
                        name: "laborShirt",
                    },
                    {
                        label: "เสื้่อแผง",
                        value: 0,
                        maxValue: 3,
                        name: "boothShirt",
                    },
                ],
            },
        ],
    },
    {
        title: "เก็บค่าที่จอดรถ",
        icon: parking,
        list: [
            {
                title: "เก็บค่าที่จอดรถ",
                menu: [
                    {
                        label: "ที่จอดรถ",
                        value: 0,
                        maxValue: 1,
                        name: "parking",
                    },
                ],
            },
        ],
    },
];

export default mock;
