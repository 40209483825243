import axios from "axios";
import { IContractResponse } from "../components/MemberManagementPage/ContractMember/PartnerContractTable/useContractTable";
import { IBooth, ILabor, ILomsak, IRider, IVeggie } from "../interfaces/Contract";

export const getAllContract = async () => {
    const res = await axios.get("/contract/all");
    return res.data as IContractResponse[];
};

export const getActiveContract = async (type: string) => {
    const res = await axios.get(`/contract/${type}/active`);
    let data;
    if (type === "booth") data = res.data as IBooth[];
    else if (type === "veggie") data = res.data as IVeggie[];
    else if (type === "rider") data = res.data as IRider[];
    else if (type === "labor") data = res.data as ILabor[];
    else if (type === "lomsak") data = res.data as ILomsak[];
    return data;
};

export const patchContract = async (contractCode: string, data: any) => {
    const res = await axios.patch(`/contract/${contractCode}`, data);
    return res.data;
};

export const patchContractMember = async (contractCode: string, memberId: string, data: any) => {
    const res = await axios.patch(`/contract/${contractCode}/${memberId}`, data);
    return res.data;
};

export const deleteContract = async (contractCode: string) => {
    const res = await axios.patch(`/contract/${contractCode}`, { status: "inactive" });
    return res.data;
};

export const deleteMemberContract = async (contractCode: string, id: string) => {
    const res = await axios.patch(`/contract/${contractCode}/${id}`, { status: "inactive" });
    return res.data;
};