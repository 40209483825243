import {
    CardContainer,
    CardEmpty,
    Container,
    FilterContainer,
    FooterContainer,
    InputSearch,
    Paragraph,
    RowContainer,
    TagStatus,
} from "./index.styles";
import CardContract from "../CardContract";
import DatePicker from "../DatePicker";
import colors from "../../../assets/colors.json";
import React from "react";
import { Empty } from "antd";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

type INote = {
    color: string;
    text: string;
};

const note: INote[] = [
    {
        color: colors.primary,
        text: "ชำระครบแล้ว",
    },
    {
        color: colors.remain,
        text: "มียอดค้างชำระภายในเดือนนี้",
    },
    {
        color: colors.error,
        text: "ผู้เช่ามียอดค้างมาจากเดือนก่อนที่ยังไม่ได้ชำระ",
    },
];

/*
    * Function for render card contract
    * use getData to get data from card contract (pass the setState to it)
    * use trigger to trigger function from card contract ex. open modal
    * use pageType to set header of card contract
    * use keyName to set key for search of data
    
    * @Note : any[] type in data is a mockup. it will change when know all of data usage
*/

// TODO: change data type if know all of data usage
type ComponentProps = {
    data: any[];
    disableStatus: boolean;
    keyName: string;
    pageType: string;
    trigger: () => void;
    getData?: (data: any) => void;
};

function LayoutContract(props: ComponentProps) {
    const { data, keyName, pageType, disableStatus } = props;
    const [dataStorage, setDataStorage] = React.useState<any[]>([]);
    const [dataUsage, setDataUsage] = React.useState<any[]>([]);
    const [searchKey, setSearchKey] = React.useState<string>("");
    const [searchDate, setSearchDate] = React.useState<Dayjs>(dayjs());

    const renderNote = note.map((item, index) => (
        <RowContainer key={index}>
            <TagStatus color={item.color} />
            <Paragraph strong>{item.text}</Paragraph>
        </RowContainer>
    ));

    React.useEffect(() => {
        setDataStorage(data);
    }, [data, setDataStorage]);

    React.useEffect(() => {
        if (!dataStorage || dataStorage.length === 0) return;

        const nData = dataStorage.filter((item) => {
            const date = dayjs(item.date);
            const isBetween = date.isBetween(
                searchDate.startOf("month"),
                searchDate.endOf("month")
            );
            // TODO: change the condition if want to search sequencial
            if (disableStatus) return item[keyName].includes(searchKey);
            return item[keyName].includes(searchKey) && isBetween;
        });
        setDataUsage(nData);
    }, [searchKey, dataStorage, keyName, searchDate, disableStatus]);

    const SearchKeys = (e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value);

    return (
        <Container>
            <FilterContainer>
                <InputSearch placeholder="ค้นหา" onChange={SearchKeys} value={searchKey} />
                {!disableStatus && (
                    <DatePicker
                        picker="month"
                        inputReadOnly
                        format="MMMM YYYY"
                        onChange={(e) => setSearchDate(dayjs(e))}
                        allowClear={false}
                        value={searchDate}
                    />
                )}
            </FilterContainer>
            {dataUsage.length === 0 ? (
                <CardEmpty>
                    <Empty description="ไม่พบผลการค้นหาข้อมูล" />
                </CardEmpty>
            ) : (
                <CardContainer>
                    {dataUsage.map((item, index) => (
                        <CardContract
                            key={index}
                            keyName={keyName}
                            status={item.status}
                            data={item}
                            pageType={pageType}
                            trigger={props.trigger}
                            getData={props.getData}
                            disableStatus={disableStatus}
                        />
                    ))}
                </CardContainer>
            )}
            {!disableStatus && <FooterContainer>{renderNote}</FooterContainer>}
        </Container>
    );
}

export default LayoutContract;
