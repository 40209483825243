import { Col, Form as F, Input, Row, Steps } from "antd";
import DatePicker from "../DatePicker";
import { Rule } from "antd/lib/form";
import styled from "styled-components";

export const FormItem = styled(F.Item)`
    display: flex;
    flex-direction: column;
    & .ant-form-item-label {
        width: fit-content;
        > label {
            &::after {
                content: "";
            }
        }
    }
    & .ant-form-item-required {
        &::before {
            content: "" !important;
        }
        &::after {
            content: "*" !important;
            color: red;
            margin-left: 0.25rem;
        }
    }
    & .ant-input {
        border-radius: 4px;
        overflow: hidden;
    }
`;

export const Form = styled(F)`
    margin: 0 auto;
    max-width: 600px;
    & > h3 {
        text-align: center;
    }
`;

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
`;

export const ContentBackground = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    & > h1 {
        text-align: center;
        font-weight: bold;
    }
`;

export const CustomSteps = styled(Steps)`
    margin: 2rem 0;
`;

export type RowGroupInputProps = {
    inputs: {
        name: string;
        label: React.ReactNode;
        rules?: Rule[];
        placeholder?: string;
        type?: "text" | "calendar";
        customInput?: React.ReactNode;
        flex?: number;
        initialValue?: any;
    }[];
    rightExtra?: React.ReactNode;
    leftExtra?: React.ReactNode;
    gutter?: number;
    
};

export const RowGroupInput: React.FC<RowGroupInputProps> = (props) => (
    
    <Row gutter={props.gutter || 12}>
        {props.leftExtra}
        {props.inputs.map(({ placeholder, type, customInput, flex, ...input }, index) => (
            <Col key={`input-${input.name}-${index}`} style={{ flex: flex || 1 }}>
                <FormItem {...input} hasFeedback > 
                    {customInput ? (
                        customInput
                    ) : type === "calendar" ? (
                        <DatePicker placeholder={placeholder} style={{ width: "100%" }} />
                    ) : (
                        <Input placeholder={placeholder}  />
                    )}
                </FormItem>
            </Col>
        ))}
        {props.rightExtra}
    </Row>
);
