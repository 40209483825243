import React from "react";
import { Tabs, Row, Col, Image } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ThunderboltOutlined, EnvironmentOutlined } from "@ant-design/icons";
import ElectricMeter from "../../../../src/assets/icons/electric-meter.png";
import WaterMeter from "../../../../src/assets/icons/water-meter.png";
import { CardType } from "./index.style";

type TabsTypeTrackProps = {
    component?: React.ReactNode;
    onSelectType: (arg: string) => void;
    typeTrack: string;
    breadcrumb: string;
};

type TypeTrackSubRouteProps = {
    key?: string | any;
    title: string;
    icon?: React.ReactNode;
    image?: string;
    component: React.ReactNode | any;
};

const CustomCardType: React.FC<TypeTrackSubRouteProps> = (props) => {
    return (
        <CardType bordered={false} key={props.key}>
            <Image src={props.image} width={60} preview={false} />
            <p>{props.title}</p>
        </CardType>
    );
};

const customTabPane: React.FC<TypeTrackSubRouteProps> = (props) => {
    return (
        <Tabs.TabPane
            tab={
                <>
                    {props.icon}
                    {props.title}
                </>
            }
            key={props.key}
        >
            {props.component}
        </Tabs.TabPane>
    );
};

const TabsTypeTrack: React.FC<TabsTypeTrackProps> = (props) => {
    const { component, onSelectType, typeTrack } = props;
    const { lg } = useBreakpoint();

    const TypeTrackSubRoute: TypeTrackSubRouteProps[] = [
        {
            title: "จดมิเตอร์น้ำ",
            icon: <EnvironmentOutlined rotate={180} />,
            component: component,
            image: WaterMeter,
        },
        {
            title: "จดมิเตอร์ไฟ",
            icon: <ThunderboltOutlined />,
            component: component,
            image: ElectricMeter,
        },
    ];

    return (
        <>
            {lg ? (
                <Tabs
                    defaultActiveKey={typeTrack}
                    onChange={(key) => {
                        onSelectType(key);
                    }}
                >
                    {TypeTrackSubRoute.map(({ title, icon, component }) => (
                        <>
                            {customTabPane({
                                key: title,
                                title: title,
                                icon: icon,
                                component: component,
                            })}
                        </>
                    ))}
                </Tabs>
            ) : typeTrack === `` ? (
                <Row gutter={[18, 18]}>
                    {TypeTrackSubRoute.map(({ title, image, component }) => (
                        <Col
                            xs={24}
                            sm={24}
                            onClick={() => {
                                onSelectType(title);
                            }}
                            defaultValue={title}
                        >
                            <CustomCardType
                                key={title}
                                title={title}
                                image={image}
                                component={component}
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <>{component}</>
            )}
        </>
    );
};

export default TabsTypeTrack;
