import { Input } from "antd";
import styled from "styled-components";

export const InputSearch = styled(Input.Search)`
    margin: 1rem 0;
`;

export const Container = styled.div`
    padding: 1rem;
    background-color: white;
`;
