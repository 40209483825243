import { Button, Row, Form, notification } from "antd";
import React from "react";
import { Container, RowGroupInput } from "../../../common/CustomForm";
import MemberForm from "../../common/MemberForm";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { antdFileToBase64 } from "../../../../functions/files";
import { CustomUpload } from "./index.style";
import validator from "../../../../assets/formValidator";
import useRegister from "../../../../pages/Register/useRegister";

const RegisterMonthlyTenantForm: React.FC<ReturnType<typeof useRegister>> = ({
    nextStep,
    tenantForm,
    setTenantForm,
}) => {
    const [imageBase64, setImageBase64] = React.useState(tenantForm?.imageBase64);
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        // Validate Image
        if (!imageBase64) return;
        setTenantForm({ ...values, imageBase64 });
        console.log({ ...values, imageBase64 });
        nextStep();
    };

    const onClickSubmit = () => {
        if (!imageBase64) {
            setImageError("กรุณาอัพโหลดรูปผู้เช่า");
            notification.error({ message: "กรุณาอัพโหลดรูปผู้เช่า" });
        }
    };

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        }
        if (info.file.status === "error" || info.file.status === "done") {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }
        setLoadingImage(false);
    }, []);

    const uploadButton = React.useMemo(
        () => (
            <div>
                {loadingImage ? (
                    <LoadingOutlined />
                ) : (
                    <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
                )}
                <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
                    {imageError || "เพิ่มรูป"}
                </div>
            </div>
        ),
        [loadingImage, imageError]
    );

    return (
        <Container>
            <Form form={form} initialValues={tenantForm} onFinish={onFinish}>
                {/* Form ของผู้เช้าแผง  */}
                <Row justify="center">
                    <h2>ข้อมูลผู้เช่า</h2>
                </Row>
                <Row justify="center">
                    <CustomUpload
                        error={imageError}
                        name="รูปผู้เช่า"
                        listType="picture-card"
                        showUploadList={false}
                        action=""
                        onChange={onChangeImage}
                    >
                        {imageBase64 ? (
                            <img src={imageBase64} alt="avatar" style={{ width: "100%" }} />
                        ) : (
                            uploadButton
                        )}
                    </CustomUpload>
                </Row>
                <MemberForm numMembers={1} memIndex={0} key={0} form={form} />
                <hr />
                {/* Form ของ ผู้เกี่ยวข้อง */}
                <Row justify="center">
                    <h2>ข้อมูลผู้เกี่ยวข้อง</h2>
                </Row>
                <RowGroupInput
                    inputs={[
                        {
                            label: "ชื่อ",
                            name: "firstname",
                            rules: [{ required: true, message: "กรุณากรอกชื่อผู้เกี่ยวข้อง" }],
                        },
                        {
                            label: "นามสกุล",
                            name: "lastname",
                            rules: [{ required: true, message: "กรุณากรอกนามสกุลผู้เกี่ยวข้อง" }],
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            label: "เบอร์โทรศัพท์",
                            name: "phoneNumber",
                            rules: [
                                { required: true, message: "กรุณากรอกเบอร์โทรศัพท์ผู้เกี่ยวข้อง" },
                                ...validator.phoneNumber,
                            ],
                        },
                    ]}
                />
                {/* Footer */}
                <Row justify="space-between">
                    <Button disabled>ย้อนกลับ</Button>
                    <Button type="primary" onClick={onClickSubmit} htmlType="submit">
                        ถัดไป
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default RegisterMonthlyTenantForm;
