import axios from "axios";
import { toast, TypeOptions } from "react-toastify";

export const showNotification = (message: string, type?: TypeOptions) => {
    if (type && type === "error") {
        toast.error(message);
    } else if (type && type === "success") {
        toast.success(message);
    } else if (type && type === "warning") {
        toast.warn(message);
    } else if (type && type === "info") {
        toast.info(message);
    } else {
        toast(message);
    }
};

export const catchingError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
        const { response } = error;
        if (response) {
            const { data } = response;
            if (data.errors && data.errors.length > 0) {
                let message = "";
                data.errors.forEach((error: any) => message += `${error.msg} \n`);
                return showNotification(message, "error");
            } else if (data.error.message) {
                return showNotification(data.error.message, "error");
            } else {
                return showNotification("เกิดข้อผิดพลาดบางประการ", "error");
            }
        }
    } else {
        return showNotification("เกิดข้อผิดพลาดบางประการ", "error");
    }
};
