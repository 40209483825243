import { Button, Input, Typography } from "antd";
import {
    ButtonAdd,
    FormItem,
    RowContainer,
    RowInputContainer,
    TimelineContainer,
    TimelineItem,
    VaccineContainer,
} from "./index.styles";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React from "react";
import DatePicker from "../../../../common/DatePicker";
import { IVaccine } from "../../../../../interfaces/Contract";

type ITimeline = {
    vaccine: IVaccine[];
    onEdit: () => void;
    edit: boolean;
};

function VaccineTimeline(props: ITimeline) {
    const { vaccine, edit, onEdit } = props;
    const Paragraph = Typography.Paragraph;

    const renderDate = (date: number) => dayjs(date).format("DD/MM/YYYY");
    const [numVaccine, setnumVaccine] = React.useState<number>(
        vaccine && vaccine.length > 0 ? vaccine.length : 1
    );

    const onAddVaccine = () => {
        !edit && onEdit();
        edit && setnumVaccine((numVaccines) => numVaccines + 1);
    };
    const deleteVaccine = () => setnumVaccine((numVaccines) => numVaccines - 1);

    React.useEffect(() => {
        setnumVaccine(vaccine && vaccine.length > 0 ? vaccine.length : 1);
    }, [setnumVaccine, vaccine, edit]);

    return (
        <VaccineContainer>
            <RowContainer>
                <Paragraph strong>ประวัติการได้รับวัคซีน</Paragraph>
                <ButtonAdd icon={<PlusCircleOutlined />} size={"small"} onClick={onAddVaccine}>
                    เพิ่มวัคซีน
                </ButtonAdd>
            </RowContainer>
            {!edit ? (
                <TimelineContainer>
                    {vaccine && vaccine.length > 0 ? (
                        vaccine.map((item: any, index: number) => (
                            <TimelineItem key={index}>
                                เข็มที่ {index + 1} - ได้รับเมื่อ {renderDate(item.date)} ชนิด{" "}
                                {item.name}
                            </TimelineItem>
                        ))
                    ) : (
                        <TimelineItem color="red">
                            <Paragraph>ไม่มีประวัติการได้รับวัคซีน</Paragraph>
                        </TimelineItem>
                    )}
                </TimelineContainer>
            ) : (
                <>
                    {Array(numVaccine)
                        .fill(null)
                        .map((_, index) => (
                            <RowInputContainer key={index}>
                                <FormItem
                                    name={`dateVaccine${index}`}
                                    label={`วันที่ฉีดวัคชีน`}
                                    rules={[
                                        {
                                            required: true,
                                            message: "กรุณาเลือกวันที่ฉีดวัคชีน",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        placeholder="เลือกวันที่ฉีดวัคชีน"
                                        format="DD/MM/YYYY"
                                        clearIcon={null}
                                    />
                                </FormItem>
                                <FormItem
                                    name={`nameVaccine${index}`}
                                    label={`ชื่อวัคซีน`}
                                    rules={[
                                        {
                                            required: true,
                                            message: "กรุณากรอกชนิดวัคซีน",
                                        },
                                    ]}
                                >
                                    <Input placeholder="ชื่อวัคซีน" />
                                </FormItem>

                                {index === numVaccine - 1 ? (
                                    <Button
                                        danger
                                        shape="circle"
                                        icon={<MinusCircleOutlined />}
                                        size={"small"}
                                        onClick={deleteVaccine}
                                        style={{ marginTop: "2.25rem" }}
                                    />
                                ) : (
                                    <Button
                                        danger
                                        shape="circle"
                                        icon={<MinusCircleOutlined />}
                                        size={"small"}
                                        style={{ marginTop: "2.75rem", visibility: "hidden" }}
                                    />
                                )}
                            </RowInputContainer>
                        ))}
                </>
            )}
        </VaccineContainer>
    );
}

export default VaccineTimeline;
