import Swal from "sweetalert2";
import { IPricing } from "../../interfaces/PriceManagement";

const convertKeyToWord = (key: string) => {
    const item: { [key: string]: string } = {
        "boothShirt-yearlyPrice": "ราคาเสื้อแผงรายปี",
        "laborShirt-monthlyPrice": "ราคาเสื้อขึ้นของรายเดือน",
        "laborShirt-yearlyPrice": "ราคาเสื้อขึ้นของรายปี",
        "laborShirt-maintaining": "ค่ารักษาสิทธิ์เสื้อขึ้นของ",
        "riderShirt-monthlyPrice": "ราคาเสื้อวินรายเดือน",
        "riderShirt-yearlyPrice": "ราคาเสื้อวินรายปี",
        "riderShirt-maintaining": "ค่ารักษาสิทธิ์เสื้อวิน",
        "lomsakCar-_4w": "ค่าเช่าประเภท 4 ล้อ (รถหล่ม)",
        "lomsakCar-_6w": "ค่าเช่าประเภท 6 ล้อ (รถหล่ม)",
        "lomsakCar-_10w": "ค่าเช่าประเภท 10 ล้อ (รถหล่ม)",
        "parking-parkingPrice": "ค่าที่จอดรถรายเดือน",
        "electrical-pricePerUnit": "ค่ามิเตอร์ไฟต่อหน่วย",
        "water-pricePerUnit": "ค่ามิเตอร์นํ้าต่อหน่วย",
    };
    return item[key];
};

export const editAlert = async (data: IPricing) => {
    let text = "";
    Object.keys(data).forEach((key) => {
        text += `${convertKeyToWord(key)} เป็น ${data[key]} บาท <br />`;
    });

    const result = await Swal.fire({
        icon: "warning",
        html: `<h3>คุณต้องการแก้ไขข้อมูลเหล่านี้ใช่หรือไม่?</h3> ${text}`,
        showCancelButton: true,
    });
    return result.value;
};

export const successAlert = async () => {
    const result = await Swal.fire({
        icon: "success",
        title: `การแก้ไขข้อมูลสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
    });
    return result.value;
};
