import { InputNumber } from "antd";
import { Title, Item } from "./index.styles";

type MeterLabelProps = {
    typelabel: "water" | "electrical";
};

export function FormOneLabel(props: MeterLabelProps) {
    const { typelabel } = props;
    return (
        <Item
            name={`${typelabel}-pricePerUnit`}
            label={
                <Title level={5}>
                    ค่ามิเตอร์{`${typelabel === "water" ? "นํ้า" : "ไฟ"}`}ต่อหน่วย
                </Title>
            }
            required
            rules={[{ required: true, message: "กรุณากรอกค่ามิเตอร์ต่อหน่วย" }]}
        >
            <InputNumber
                addonAfter="บาท"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
            />
        </Item>
    );
}
