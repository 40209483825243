import { Avatar, Typography } from "antd";
import { ButtonPrint, Container, NameContainer, TagItem, TextPrinter } from "./index.styles";
import { PrinterOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import BadgeStatus from "../../BadgeStatus";
import { randomColor } from "../../../../../functions/randomColor";

type ITitleModal = {
    record: any;
    onPrintData: () => void;
};

export type IColor = {
    status: "active" | "inactive" | "onHold";
};

const convertToStatus = (status: IColor["status"]) => {
    switch (status) {
        case "active":
            return "ปกติ";
        case "inactive":
            return "พักงาน";
        case "onHold":
            return "รักษาสิทธิ์";
    }
};

const convertToBadge = (status: IColor["status"]) => {
    switch (status) {
        case "active":
            return "success";
        case "inactive":
            return "default";
        case "onHold":
            return "warning";
    }
};
function TitleModal(props: ITitleModal) {
    const { record, onPrintData } = props;
    const { firstName, lastName, status } = record;
    const { Title } = Typography;
    const { xs } = useBreakpoint();
    
    return (
        <Container>
            <Avatar size={xs ? 48 : 64} style={{backgroundColor: randomColor()}}>
                {firstName}
            </Avatar>
            <NameContainer>
                <Title level={4}>{firstName + " " + lastName}</Title>
                <TagItem
                    icon={<BadgeStatus status={status} size="0.6rem" marginRight="0.3rem" />}
                    color={convertToBadge(status)}
                >
                    {convertToStatus(status)}
                </TagItem>
            </NameContainer>
            <ButtonPrint onClick={onPrintData}>
                <PrinterOutlined style={{ fontSize: 30 }} />
                <TextPrinter strong>รายละเอียดสัญญา</TextPrinter>
            </ButtonPrint>
        </Container>
    );
}
export default TitleModal;
