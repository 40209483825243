import styled from "styled-components";
import { Typography, Form } from "antd";

export const Title = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;

export const Item = styled(Form.Item)`
  gap: ${(props: { gap?: string }) => props.gap || "5rem"} !important;
  @media only screen and (max-width: 768px) {
    gap: 0 !important;
  }
`;

export const ContainerOtherPrice = styled.div`
  margin-left: 2rem;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`;
