import { IBoothMember, IVaccine } from "../../../../../interfaces/Contract";
import { IContractResponse } from "../useContractTable";

type IFormValue = { [key: string]: any };
export const filterValues = (values: IFormValue, oldValue: IContractResponse | IBoothMember) => {
    const vaccine: IVaccine[] = [];
    const involved: { [key: string]: string } = {};
    const data: { [key: string]: any } = {};
    Object.keys(values).forEach((key) => {
        if (key.includes("dateVaccine")) {
            const index = key.replace("dateVaccine", "");
            const nameVaccine = `nameVaccine${index}`;
            const dateVaccine = `dateVaccine${index}`;
            vaccine.push({
                name: values[nameVaccine],
                date: values[dateVaccine].valueOf(),
            });
        }
        if (key.includes("firstNameInvolved")) {
            const firstName = `firstName`;
            const lastName = `lastName`;
            const tel = `tel`;
            involved[firstName] = values[`${firstName}Involved`];
            involved[lastName] = values[`${lastName}Involved`];
            involved[tel] = values[`${tel}Involved`];
        }
        if (
            oldValue &&
            values[key] !== oldValue[key] &&
            !key.includes("Vaccine") &&
            !key.includes("address") &&
            !key.includes("booth") &&
            !key.includes("Involved")
        ) {
            data[key] = values[key];
        }
    });

    data["vaccine"] = vaccine;

    Object.keys(involved).forEach((key) => {
        if (oldValue && involved[key] === oldValue.involved[key]) {
            delete involved[key];
        }
    });

    data["involved"] = involved;
    return data;
};
