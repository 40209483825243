import { Container, Extra, Paragraph, TagStatus, Title } from "./index.styles";
import colors from "../../../assets/colors.json";

// TODO: will change when know all of data usage
type ICardContract = {
    keyName: string;
    status?: string;
    disableStatus: boolean;
    pageType: string;
    data: any;
    trigger: () => void;
    getData?: (data: any) => void;
};

function CardContract(props: ICardContract) {
    const { disableStatus, pageType, data, trigger, getData, keyName, status } = props;

    const convertColor = (state: string) => {
        switch (state) {
            case "active":
                return colors.primary;
            case "inactive":
                return colors.error;
            default:
                return colors.memberDetailWorkColor.subDuty;
        }
    };

    const convertType = (type: string) => {
        switch (type) {
            case "booth":
                return "ช่องที่";
            case "rider":
                return "เบอร์เสื้อวิน";
            case "labor":
                return "เบอร์เสื้อแผง";
        }
    };

    const onClick = () => {
        getData && getData(data);
        trigger();
    };

    return (
        <Container onClick={onClick}>
            <Extra>
                <Paragraph strong>{convertType(pageType)}</Paragraph>
                {!disableStatus && status && <TagStatus color={convertColor(status)} />}
            </Extra>
            <Title>{data[keyName]}</Title>
        </Container>
    );
}

export default CardContract;
