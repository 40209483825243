import { UploadFile } from "antd/lib/upload/interface";

export const antdFileToBase64 = async (file: UploadFile) => {
    let src = file.url;
    if (!src) {
        src = await new Promise((resolve) => {
            const reader = new FileReader();
            if (!file.originFileObj) return console.log("no file");

            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result as string);
        });
    }
    return src;
};

export const blobToBase64 = async (
    blob: Blob,
    callback?: (base64: string | ArrayBuffer | null) => void
) => {
    const base64 = await new Promise<string | ArrayBuffer | null>((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
            callback && callback(reader.result);
        };
        reader.readAsDataURL(blob);
    });
    return base64;
};

export const base64ToBlob = async (base64: string, callback?: (blob: Blob) => void) => {
    const base64Response = await fetch(base64);
    const blob = await base64Response.blob();
    callback && callback(blob);
    return blob;
};
