import styled from "styled-components";
import colors from "../../../assets/colors.json";

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    & h2 {
        text-align: left;
    }
`;

export const SubRouter = styled.div`
    margin: 0 1rem;
    border-bottom: 1px solid ${colors.border};
    padding: 1rem 0;

    & span {
        padding: 1rem 0.5rem;
        cursor: pointer;
        color: ${colors.hintText};
    }
    & span.active {
        color: ${colors.primary};
        border-bottom: 2px solid ${colors.primary};
    }
`;
