import { Container, ContentBackground, } from "../../../../components/common/CustomForm";
import RegisterLaborShirt from "../../../../components/RegisterPage/Shirt/LaborShirt";

function RegisterLaborShirtPage() {
    return (
        <Container>
            <ContentBackground>
                <h1>ลงทะเบียนเสื้อขึ้นของ</h1>
                <RegisterLaborShirt />

            </ContentBackground>
        </Container>
    );
}

export default RegisterLaborShirtPage;