import { columns } from "../common/columns";
import { DeleteOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { FormInstanceType } from "../ModalComponents/FormContent";
import React from "react";

type ICreateColumn = {
    type: Omit<FormInstanceType, "inner">;
    onDelete: (id: string) => void;
};

function CreateColumns(props: ICreateColumn) {
    const { type, onDelete } = props;

    const removeKey = (key: string, { [key]: _, ...rest }) => rest;
    const renderDeleteButton = (_: string, record: any, __: number) => (
        <DeleteOutlined
            onClick={(e: any) => {
                e.stopPropagation();
                onDelete(record);
            }}
        />
    );

    return columns.map((column, index) => {
        if (column.renderType.includes(type)) {
            const objWithoutType = removeKey("renderType", column);
            if (column.dataIndex.includes("contractCode")) {
                objWithoutType["render"] = renderDeleteButton;
                return <Column {...objWithoutType} key={index} align="center" />;
            }
            return <Column {...objWithoutType} key={index} align="center" />;
        }
        return <React.Fragment key={index}></React.Fragment>;
    });
}

export default CreateColumns;
