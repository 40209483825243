import React from "react";
import { Form, FormInstance, Table } from "antd";
import useContractTable, { IContractResponse } from "./useContractTable";
import { Container, InputSearch } from "./index.styles";
import ContractInformationModal from "../ContractInformationModal";
import { FormInstanceType } from "../ModalComponents/FormContent";
import CreateColumns from "../CreateColumns";
import { IBooth, IBoothMember } from "../../../../interfaces/Contract";

type SubRecord = IBoothMember;

function PartnerContractTable(props: { type: FormInstanceType }) {
    const { type } = props;
    const { formState, dataUsage, search, innerModal, mainModal, rowState } =
        useContractTable(type);
    const { searchKey, SearchKeys } = search;
    const { visibleInner, OpenInnerModal, CloseInnerModal } = innerModal;
    const { visible, OpenModal, CloseModal, onCancelEdit, TriggerEdit, onPrintData, edit } =
        mainModal;
    const { recordRow, setRecordRow, onDeleteRow } = rowState;
    const { form, onFinish, isLoading, showFormValue } = formState;

    const ref = React.useRef<FormInstance<any>>(null);
    const includeData: FormInstanceType[] = ["rider", "labor", "lomsak", "veggie"];

    const expandedRowRender = (record: IContractResponse) => {
        const data = record as IBooth;
        return (
            <Table
                rowKey={(record: SubRecord) => record["memberCode"]}
                dataSource={data.member as SubRecord[]}
                pagination={false}
                onRow={(record, _) => {
                    return {
                        onClick: () => {
                            setRecordRow(record);
                            showFormValue(record);
                            OpenInnerModal();
                        },
                    };
                }}
            >
                {CreateColumns({ type: "inner", onDelete: onDeleteRow })}
            </Table>
        );
    };

    return (
        <>
            <InputSearch
                size="large"
                placeholder="ค้นหาด้วย ชื่อ - นามสกุล / เบอร์โทรศัพท์ / อื่น ๆ"
                onChange={SearchKeys}
                value={searchKey}
            />
            <Container>
                <Table
                    rowKey={(record) => record["contractCode"]}
                    dataSource={dataUsage}
                    expandable={!includeData.includes(type) ? { expandedRowRender } : undefined}
                    scroll={{ x: "calc(500px + 50%)" }}
                    footer={() => `จำนวนข้อมูลทั้งหมด ${dataUsage.length} รายการ`}
                    loading={isLoading}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                setRecordRow(record);
                                showFormValue(record);
                                OpenModal();
                            },
                        };
                    }}
                >
                    {CreateColumns({ type, onDelete: onDeleteRow })}
                </Table>
            </Container>
            <Form form={form} ref={ref} onFinish={onFinish}>
                <ContractInformationModal
                    type={type}
                    visible={visible}
                    visibleInner={visibleInner}
                    editable={edit}
                    record={recordRow as IContractResponse | IBoothMember}
                    onOk={TriggerEdit}
                    onCancel={!visibleInner ? CloseModal : CloseInnerModal}
                    onEdit={TriggerEdit}
                    onCancelEdit={onCancelEdit}
                    onPrintData={onPrintData}
                />
            </Form>
        </>
    );
}

export default PartnerContractTable;
