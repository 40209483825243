import styled from "styled-components";
import colors from "../../../../assets/colors.json";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    & h3 {
        border-bottom: 1px solid ${colors.border};
        margin: 1rem 0 0 0;
    }
`;

export const MenuWorkValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0 1rem;
  & > p {
    margin: 0;
    & > span {
      color: ${colors.hintText};
    }
`;
