import { FormInstance } from "antd";
import dayjs from "dayjs";
import { IBoothMember, IVaccine } from "../../../../../interfaces/Contract";
import { IContractResponse } from "../useContractTable";

export const initValuesForm = (form: FormInstance, record: IContractResponse | IBoothMember) => {
    Object.keys(record).forEach((key) => {
        form.setFieldsValue({ [key]: record[key] });
        if (key === "address") {
            let address = "";
            Object.keys(record["address"]).forEach(
                (innerkey) =>
                    record.address[innerkey] && (address += `${record.address[innerkey]} `)
            );
            form.setFieldsValue({ [key]: address });
        }
        if (record.vaccine && record.vaccine.length > 0) {
            record.vaccine.forEach((vac: IVaccine, index: number) => {
                const dateVaccine = `dateVaccine${index}`;
                const nameVaccine = `nameVaccine${index}`;
                form.setFieldsValue({
                    [dateVaccine]: dayjs(vac.date),
                    [nameVaccine]: vac.name,
                });
            });
        }
        if (record.involved) {
            Object.keys(record.involved).forEach((key) => {
                form.setFieldsValue({ [`${key}Involved`]: record.involved[key] });
            });
        }
    });
};
