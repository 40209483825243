import React from "react";
import Breadcrumb from "../../components/TrackMeter/Breadcrumb";
import { Row, Col, Image } from "antd";
import CardBuildingSelector from "../../components/common/CardBuildingSelector";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import BoothLayout1 from "../../../src/assets/icons/booth-layout1.png";
import BoothLayout2 from "../../../src/assets/icons/booth-layout2.png";
import BoothLayout3 from "../../../src/assets/icons/booth-layout3.png";
import TabsTypeTrack from "../../components/TrackMeter/TabsTypeTrack";
import SymbolStatus from "../../components/TrackMeter/SymbolStatus";

type mockData = {
    type: String;
    dataTable: {
        key?: string;
        building: string;
        rent: number;
        available: number;
        boothLayout: string;
    }[];
}[];

const TrackMeter = () => {
    const { lg } = useBreakpoint();

    const mockData: mockData = [
        {
            type: "จดมิเตอร์น้ำ",
            dataTable: [
                {
                    key: "1",
                    building: "อาคาร 1",
                    rent: 12,
                    available: 2,
                    boothLayout: BoothLayout1,
                },
                {
                    key: "2",
                    building: "อาคาร 2",
                    rent: 21,
                    available: 3,
                    boothLayout: BoothLayout2,
                },
                {
                    key: "3",
                    building: "อาคาร 3",
                    rent: 32,
                    available: 2,
                    boothLayout: BoothLayout3,
                },
            ],
        },
        {
            type: "จดมิเตอร์ไฟ",
            dataTable: [
                {
                    key: "1",
                    building: "อาคาร 11",
                    rent: 12,
                    available: 2,
                    boothLayout: BoothLayout3,
                },
                {
                    key: "2",
                    building: "อาคาร 22",
                    rent: 21,
                    available: 3,
                    boothLayout: BoothLayout1,
                },
                {
                    key: "3",
                    building: "อาคาร 33",
                    rent: 32,
                    available: 2,
                    boothLayout: BoothLayout2,
                },
            ],
        },
    ];

    const dataTable = [
        {
            key: "1",
            building: "อาคาร 1",
            rent: 12,
            available: 2,
            boothLayout: BoothLayout1,
        },
        {
            key: "2",
            building: "อาคาร 2",
            rent: 21,
            available: 3,
            boothLayout: BoothLayout2,
        },
        {
            key: "3",
            building: "อาคาร 3",
            rent: 32,
            available: 2,
            boothLayout: BoothLayout3,
        },
    ];
    const [buildingSelect, setBuildingSelect] = React.useState<string>(
        mockData[0].dataTable[0].building
    );

    const [typeTrack, setTypeTrack] = React.useState<string>("");
    const [breadcrumb, setBreadcrumb] = React.useState<string>("");

    React.useEffect(() => {
        if (lg) {
            setTypeTrack("มิเตอร์น้ำ");
        }
    }, [lg]);

    const onSelectType = (name: string): void => {
        setTypeTrack(name);
    };

    return (
        <div style={{ margin: "0 1rem" }}>
            <Breadcrumb
                labels={["แผงรายเดือน", typeTrack, buildingSelect]}
                onClick={(index) => {
                    setBreadcrumb(index);
                }}
            />
            <Row gutter={[18, 0]} justify="space-between">
                <Col xs={24} sm={24} lg={11}>
                    <TabsTypeTrack
                        onSelectType={onSelectType}
                        typeTrack={typeTrack}
                        breadcrumb={breadcrumb}
                        component={
                            <CardBuildingSelector
                                type="booth-monthly"
                                data={dataTable}
                                onSelect={setBuildingSelect}
                                buildingSelect={buildingSelect}
                            />
                        }
                    />
                </Col>
                {lg || (!lg && typeTrack !== ``) ? (
                    <Col sm={24} lg={12}>
                        <Row justify="space-between" align="middle">
                            <p>{buildingSelect}</p>
                            <SymbolStatus />
                        </Row>
                        {dataTable.map(({ boothLayout, building }) => (
                            <>
                                {" "}
                                {buildingSelect === building ? (
                                    <Image src={boothLayout} width={"100%"} preview={false} />
                                ) : (
                                    <></>
                                )}
                            </>
                        ))}
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
        </div>
    );
};

export default TrackMeter;
