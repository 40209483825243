import { InputNumber } from "antd";
import { Item, Title, ContainerOtherPrice } from "./index.styles";

type ShirtLabelProps = {
    typelabel: "parking" | "boothShirt";
};

export function FormOneLabel(props: ShirtLabelProps) {
    const { typelabel } = props;
    return (
        <Item
            name={typelabel === "parking" ? "parking-parkingPrice" : "boothShirt-yearlyPrice"}
            label={
                <Title level={5}>
                    {typelabel === "parking" ? "ราคาค่าเช่ารายเดือน" : "ราคาค่าเสื้อรายปี"}
                </Title>
            }
            rules={[
                {
                    required: true,
                    message: `กรุณากรอก${
                        typelabel === "parking" ? "ราคาค่าเช่ารายเดือน" : "ราคาค่าเสื้อรายปี"
                    }`,
                },
            ]}
        >
            <InputNumber
                addonAfter="บาท"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
            />
        </Item>
    );
}

export function FormLomsak() {
    return (
        <div>
            <Item
                name="lomsakCar-_4w"
                label={<Title level={5}>ค่าเช่าประเภท 4 ล้อ</Title>}
                rules={[{ required: true, message: `กรุณากรอกค่าเช่าประเภท 4 ล้อ` }]}
            >
                <InputNumber
                    addonAfter="บาท"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                />
            </Item>
            <Item
                name="lomsakCar-_6w"
                label={<Title level={5}>ค่าเช่าประเภท 6 ล้อ</Title>}
                rules={[{ required: true, message: `กรุณากรอกค่าเช่าประเภท 6 ล้อ` }]}
            >
                <InputNumber
                    addonAfter="บาท"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                />
            </Item>
            <Item
                name="lomsakCar-_10w"
                gap="4.6rem"
                label={<Title level={5}>ค่าเช่าประเภท 10 ล้อ</Title>}
                rules={[{ required: true, message: `กรุณากรอกค่าเช่าประเภท 10 ล้อ` }]}
            >
                <InputNumber
                    addonAfter="บาท"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                />
            </Item>
        </div>
    );
}

export function FormLaborAndRiderShirt(props: { type: string }) {
    const { type } = props;
    return (
        <div>
            <Item
                name={`${type}-monthlyPrice`}
                label={<Title level={5}>ราคาค่าเสื้อรายเดือน</Title>}
                rules={[{ required: true, message: `กรุณากรอกราคาค่าเสื้อรายเดือน` }]}
            >
                <InputNumber
                    addonAfter="บาท"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                />
            </Item>
            <Item
                name={`${type}-yearlyPrice`}
                gap="6.5rem"
                label={<Title level={5}>ราคาค่าเสื้อรายปี</Title>}
                rules={[{ required: true, message: `กรุณากรอกราคาค่าเสื้อรายปี` }]}
            >
                <InputNumber
                    addonAfter="บาท"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                />
            </Item>
            <Title level={5}>ราคาอื่น</Title>
            <ContainerOtherPrice>
                <Item
                    name={`${type}-maintaining`}
                    gap="2rem"
                    label={<Title level={5}>ค่ารักษาสิทธิ์ (ต่อเดือน)</Title>}
                    rules={[{ required: true, message: `กรุณากรอกค่ารักษาสิทธิ์` }]}
                >
                    <InputNumber
                        addonAfter="บาท"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => `${value}`.replace(/\$\s?|(,*)/g, "")}
                    />
                </Item>
            </ContainerOtherPrice>
        </div>
    );
}
