import { Container } from "../index.styles";
import LayoutContract from "../../../components/common/LayoutContract";
import data from "../../../mock/LayoutComponent/boothName_date.json";
import React from "react";
import ModalCardManage from "../../../components/PriceManagementPage/ModalCardManage";
import CardBuildingSelector from "../../../components/common/CardBuildingSelector";
import { Row, Col } from "antd";

type mockData = {
    type: String;
    dataTable: {
        key?: string;
        building: string;
        rent: number;
        available: number;
    }[];
}[];

function VeggieBoothManagement() {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const showModal = () => setIsModalVisible(true);
    const handleOk = () => setIsModalVisible(false);
    const handleCancel = () => setIsModalVisible(false);
    // const [edit, setEdit] = React.useState<boolean>(false);
    const mockData: mockData = [
        {
            type: "จดมิเตอร์น้ำ",
            dataTable: [
                {
                    key: "1",
                    building: "อาคาร 1",
                    rent: 12,
                    available: 2,
                },
                {
                    key: "2",
                    building: "อาคาร 2",
                    rent: 21,
                    available: 3,
                },
                {
                    key: "3",
                    building: "อาคาร 3",
                    rent: 32,
                    available: 2,
                },
            ],
        },
        {
            type: "จดมิเตอร์ไฟ",
            dataTable: [
                {
                    key: "1",
                    building: "อาคาร 11",
                    rent: 12,
                    available: 2,
                },
                {
                    key: "2",
                    building: "อาคาร 22",
                    rent: 21,
                    available: 3,
                },
                {
                    key: "3",
                    building: "อาคาร 33",
                    rent: 32,
                    available: 2,
                },
            ],
        },
    ];
    const dataTable = [
        {
            key: "1",
            building: "อาคาร 1",
            rent: 12,
            available: 2,
        },
        {
            key: "2",
            building: "อาคาร 2",
            rent: 21,
            available: 3,
        },
        {
            key: "3",
            building: "อาคาร 3",
            rent: 32,
            available: 2,
        },
    ];
    const [buildingSelect, setBuildingSelect] = React.useState<string>(
        mockData[0].dataTable[0].building
    );
    // const [form] = Form.useForm();

    const [value, setValue] = React.useState<any>();
    console.log(value);

    return (
        <Container style={{ flexDirection: "row" }}>
            <Row justify="space-between">
                <Col xs={24} sm={24} lg={9}>
                    <CardBuildingSelector
                        type="booth-monthly"
                        data={dataTable}
                        onSelect={setBuildingSelect}
                        buildingSelect={buildingSelect}
                    />
                </Col>
                <Col xs={24} sm={24} lg={14}>
                    <LayoutContract
                        data={data}
                        keyName="boothName"
                        pageType="booth"
                        trigger={showModal}
                        getData={setValue}
                        disableStatus={true}
                    />
                </Col>
                <ModalCardManage
                    visible={isModalVisible}
                    boothList={dataTable}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                />
            </Row>
        </Container>
    );
}

export default VeggieBoothManagement;
