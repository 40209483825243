import { Container, ContentBackground, } from "../../../../components/common/CustomForm";
import RegisterBoothShirt from "../../../../components/RegisterPage/Shirt/BoothShirt"

function RegisterBoothShirtPage() {
    return (
        <Container>
            <ContentBackground>
                <h1>ลงทะเบียนเสื้อแผง</h1>
                <RegisterBoothShirt />

            </ContentBackground>
        </Container>
    );
}

export default RegisterBoothShirtPage;