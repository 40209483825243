import axios from "axios";
import { BoothType, VeggieType } from "../interfaces/Register";

export const getBuildingMonthly = async () => {
    const response = await axios.get(`/zone/booth/all`);
    return response.data;
};
export const getBuildingVeggie = async () => {
    const response = await axios.get(`/zone/veggie/all`);
    return response.data;
};

export const createContract = async (contract: BoothType | VeggieType) => {
    const response = await axios.post<{ documentID: string }>(`/contract/create`, contract);
    return response.data;
};

export const downloadContractPromise = async (contractCode: string, name?: string) => {
    const path = `/pdf/contract/${contractCode}`;
    return axios({
        url: path,
        method: "GET",
        responseType: "blob", // !!! important
    }).then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute("download", `สัญญาเช่าพื้นที่ของคุณ${name ? name : ""}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
};
