import React from "react";
import { Image } from "antd";
import { getQRRegisterLine } from "../../apis/registerLine";

const TestQRLine = () => {
    const boothNumber: string = "z1a1#1";
    const [imageQR, setImageQR] = React.useState<string>("");
    const onGetQRRegisterLine = React.useCallback(async () => {
        try {
            setImageQR(await getQRRegisterLine(boothNumber));
        } catch (error) {
            console.log("error", error);
        }
    }, []);

    React.useEffect(() => {
        onGetQRRegisterLine();
    });

    return (
        <div style={{ textAlign: "center", marginTop: "3rem" }}>
            <h1>ทดสอบการยิงรับการแจ้งเตือนจาก LINE ของแผง z1a1#1</h1>
            <Image width={600} src={imageQR} />
        </div>
    );
};

export default TestQRLine;
