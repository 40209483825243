import { Form } from "antd";
import React from "react";
import { FormInstanceType } from "../ModalComponents/FormContent";
import dayjs from "dayjs";
import {
    IBooth,
    IVeggie,
    IRider,
    ILabor,
    ILomsak,
    IBoothMember,
} from "../../../../interfaces/Contract";
import {
    deleteContract,
    deleteMemberContract,
    getActiveContract,
    patchContract,
    patchContractMember,
} from "../../../../services/contract";
import { deleteAlert, editAlert, exitOnEditAlert, exportAlert, successAlert } from "../alert";
import { catchingError, showNotification } from "../../../../functions/notification";
import { getIncludes } from "./utility/includes";
import { filterValues } from "./utility/filterValues";
import { initValuesForm } from "./utility/initValuesForm";
import { downloadContractPromise } from "../../../../apis/register";

export type IContractResponse = (IBooth | IVeggie | IRider | ILabor | ILomsak) & {
    [key: string]: any;
};

function useContractTable(type: FormInstanceType) {
    const [dataStorage, setDataStorage] = React.useState<IContractResponse[]>();
    const [dataUsage, setDataUsage] = React.useState<IContractResponse[]>([]);
    const [searchKey, setSearchKey] = React.useState<string>("");
    const [recordRow, setRecordRow] = React.useState<IBoothMember | IContractResponse>();
    const [visible, setVisible] = React.useState<boolean>(false);
    const [visibleInner, setVisibleInner] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [form] = Form.useForm();

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            let data = await getActiveContract(type);
            setDataStorage(data);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, [type, setIsLoading]);

    React.useEffect(() => {
        fetchData();
        return () => {
            fetchData();
        };
    }, [fetchData]);

    React.useEffect(() => {
        if (!dataStorage || dataStorage.length === 0) return;

        const nData = dataStorage.filter((item) => {
            const date = dayjs(item.startDate).format("DD/MM/YYYY");
            return getIncludes(searchKey, type, item, date);
        });
        setDataUsage(nData);
    }, [searchKey, dataStorage, type]);

    const OpenModal = () => setVisible(!visible);

    const CloseModal = async () => {
        const result = edit ? await exitOnEditAlert() : true;
        if (result) {
            setEdit(false);
            setVisible(false);
        }
        setEdit(false);
    };

    const TriggerLoading = () => setIsLoading(!isLoading);

    const SearchKeys = (e: React.ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value);

    const OpenInnerModal = () => setVisibleInner(!visibleInner);

    const CloseInnerModal = async () => {
        const result = edit ? await exitOnEditAlert() : true;
        if (result) {
            setEdit(false);
            setVisibleInner(false);
        }
        setEdit(false);
    };

    const TriggerEdit = () => {
        !edit && setEdit(!edit);
        edit && form.submit();
    };

    const onCancelEdit = async () => {
        const result = edit ? await exitOnEditAlert() : true;
        if (result) {
            setEdit(!edit);
        }
        showFormValue(recordRow as IContractResponse | IBoothMember);
    };

    const showFormValue = (record: IContractResponse | IBoothMember) => {
        form.resetFields();
        initValuesForm(form, record);
    };

    const onDeleteRow = async (record: any) => {
        const result = await deleteAlert(record);
        if (result) {
            try {
                setIsLoading(true);
                if (record.memberCode) {
                    await deleteMemberContract(record.contractCode, record.memberCode);
                    await fetchData();
                } else {
                    await deleteContract(record.contractCode);
                    setDataUsage((d) => d.filter((i) => i.contractCode !== record.contractCode));
                }
                await successAlert();
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onFinish = async (values: { [key: string]: any }) => {
        const data = filterValues(values, recordRow as IContractResponse | IBoothMember);
        const result = await editAlert(data);
        if (result) {
            setIsLoading(true);
            setEdit(false);
            setVisibleInner(false);
            setVisible(false);
            try {
                setIsLoading(true);
                const contractCode = recordRow?.contractCode as string;
                if (visibleInner) {
                    await patchContractMember(contractCode, recordRow?.memberCode, data);
                } else {
                    await patchContract(contractCode, data);
                }
                await fetchData();
                setIsLoading(false);
                await successAlert();
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onPrintData = async () => {
        await exportAlert();
        setEdit(false);
        setVisible(false);
        setVisibleInner(false);
        try {
            await downloadContractPromise(recordRow?.contractCode as string);
            showNotification("นำออกเอกสารสำเร็จ", "success");
        } catch (error) {
            showNotification("นำออกเอกสารล้มเหลว", "error");
        }
    };

    return {
        dataUsage,
        rowState: {
            recordRow,
            setRecordRow,
            onDeleteRow,
        },
        formState: {
            form,
            onFinish,
            isLoading,
            TriggerLoading,
            showFormValue,
        },
        search: {
            searchKey,
            SearchKeys,
        },
        innerModal: {
            visibleInner,
            OpenInnerModal,
            CloseInnerModal,
        },
        mainModal: {
            visible,
            OpenModal,
            CloseModal,
            onCancelEdit,
            TriggerEdit,
            onPrintData,
            edit,
        },
    };
}
export default useContractTable;
