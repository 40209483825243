import { Button } from "antd";
import { Container } from "./index.styles";

type IFooter = {
    onOk: () => void;
    onClose: () => void;
    onEdit: () => void;
    onCancelEdit: () => void;
    edit: boolean;
};

function FooterModal(props: IFooter) {
    const { onOk, onClose, onEdit, onCancelEdit, edit } = props;
    return (
        <Container>
            {edit ? (
                <Button size="large" onClick={onCancelEdit}>
                    ยกเลิก
                </Button>
            ) : (
                <Button size="large" onClick={onClose}>
                    ปิดหน้านี้
                </Button>
            )}
            {edit ? (
                <Button type="primary" size="large" onClick={onEdit}>
                    ยืนยันการแก้ไข
                </Button>
            ) : (
                <Button type="primary" size="large" onClick={onOk}>
                    แก้ไขข้อมูล
                </Button>
            )}
        </Container>
    );
}

export default FooterModal;
