import axios from "axios";
import {
    MemberType,
    RandomAssignValueType,
    WorkManagementCardType,
} from "../interfaces/MemberManagement";
import meterIcon from "../assets/icons/meter.png";
import shop from "../assets/icons/shop.png";
import shirt from "../assets/icons/shirt.png";
import parking from "../assets/icons/parking.png";

export const getAllMember = async () => {
    const result = await axios.get<MemberType[]>("/users/all");
    return result.data;
};

export const patchUpdateDutyMember = async (
    uid: string,
    mainDuty?: string,
    dutyPool?: string[]
) => {
    await axios.patch(`/users/assignDuty`, { uid, duty: { mainDuty, dutyPool } });
};

export const getRandomAssignValue = async () => {
    const result = await axios.get<RandomAssignValueType>("/random/assignValue");
    const template: (_: RandomAssignValueType) => WorkManagementCardType[] = (props) => [
        {
            title: "จดมิเตอร์ค่าน้ำ/ไฟ",
            icon: meterIcon,
            list: [
                {
                    title: "จดมิเตอร์น้ำ/ไฟ",
                    menu: [
                        {
                            label: "แผงรายเดือน",
                            value: props?.meterBooth?.currValue || 0,
                            maxValue: props?.meterBooth?.maxValue || 0,
                            name: "meterBooth",
                        },
                        {
                            label: "แผงผักบ้าน",
                            value: props?.meterVeggie?.currValue || 0,
                            maxValue: props?.meterVeggie?.maxValue || 0,
                            name: "meterVeggie",
                        },
                    ],
                },
            ],
        },
        {
            title: "เก็บค่าเช่า",
            icon: shop,
            list: [
                {
                    title: "เก็บค่าเช่า",
                    menu: [
                        {
                            label: "แผงรายเดือน",
                            value: props?.booth?.currValue || 0,
                            maxValue: props?.booth?.maxValue || 0,
                            name: "booth",
                        },
                        {
                            label: "แผงผักบ้าน",
                            value: props?.veggie?.currValue || 0,
                            maxValue: props?.veggie?.maxValue || 0,
                            name: "veggie",
                        },
                        {
                            label: "รถหล่ม",
                            value: props?.lomsak?.currValue || 0,
                            maxValue: props?.lomsak?.maxValue || 0,
                            name: "lomsak",
                        },
                    ],
                },
            ],
        },
        {
            title: "เก็บค่าเสื้อ",
            icon: shirt,
            list: [
                {
                    title: "เก็บค่าเสื้อ",
                    menu: [
                        {
                            label: "เสื้่อวิน",
                            value: props?.riderShirt?.currValue || 0,
                            maxValue: props?.riderShirt?.maxValue || 0,
                            name: "riderShirt",
                        },
                        {
                            label: "เสื้่อขึ้นของ",
                            value: props?.laborShirt?.currValue || 0,
                            maxValue: props?.laborShirt?.maxValue || 0,
                            name: "laborShirt",
                        },
                        {
                            label: "เสื้่อแผง",
                            value: props?.boothShirt?.currValue || 0,
                            maxValue: props?.boothShirt?.maxValue || 0,
                            name: "boothShirt",
                        },
                    ],
                },
            ],
        },
        {
            title: "เก็บค่าที่จอดรถ",
            icon: parking,
            list: [
                {
                    title: "เก็บค่าที่จอดรถ",
                    menu: [
                        {
                            label: "ที่จอดรถ",
                            value: props?.parking?.currValue || 0,
                            maxValue: props?.parking?.maxValue || 0,
                            name: "parking",
                        },
                    ],
                },
            ],
        },
    ];
    return { temp: template(result.data), data: result.data as RandomAssignValueType };
};

export const updateValueRandom = async (update: { [key: string]: number }) =>
    await axios.patch("/random", update);
