import styled from "styled-components";
// import colors from "../../../assets/colors.json";

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    & h2 {
        text-align: left;
    }
`;

export const Content = styled.div`
    margin: 0 1rem;
`;

export const TabTitle = styled.div`
    width: 100px;
    text-align: center;
`;
