// import { Button, Row, Form, Col, notification, message, Grid, Select } from "antd";
// import DatePicker from "../../../common/DatePicker";
// import dayjs, { Dayjs } from "dayjs";
// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
// import React from "react";
// import { Container } from "../../../common/CustomForm";
// import { CustomUpload } from "./index.style";
// import { RowGroupInput } from "../../../common/CustomForm";
// import { antdFileToBase64, base64ToBlob } from "../../../../functions/files";
// import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
// import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
// import { getBuildingMonthly } from "../../../../apis/register";
// import validator from "../../../../assets/formValidator";
// import { BuildingType } from "../../../../interfaces/Register";
// import { uploadImageReturnUrl } from "../../../../functions/storage";
// import { LomsakType } from "../../../../interfaces/Register";
// type ShirtProps = {
//     initVaccines?: number;
// };
// const { Option } = Select;
// const RegisterLomSak: React.FC<ShirtProps> = (props) => {
//     const { useBreakpoint } = Grid;
//     const { xs } = useBreakpoint();
//     const [form] = Form.useForm();
//     const [imageBase64, setImageBase64] = React.useState<string>();
//     const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
//     const [imageError, setImageError] = React.useState<string>();
//     const [numVaccines, setVaccines] = React.useState<number>(props.initVaccines || 1);
//     const [isLoading, setIsLoading] = React.useState(false);
//     const { RangePicker } = DatePicker;
//     const [buildingSelect, setBuildingSelect] = React.useState<string>();
//     const [boothSelect, setBoothSelect] = React.useState<string>();
//     const [carTypeSelect, setCarTypeSelect] = React.useState<string>("");
//     const [buildingStorage, setBuildingStorage] = React.useState<BuildingType>({});
//     const [carSizeSelect, setCarSizeSelect] = React.useState<"4w" | "6w" | "10w">("4w");

//     const carType: { typeName: string; typeCode: string }[] = [
//         { typeName: "รถหล่ม", typeCode: "Lomsak" },
//     ];
//     const carSize: { sizeName: string; sizeCode: string }[] = [
//         { sizeName: "4 ล้อ", sizeCode: "4w" },
//         { sizeName: "6 ล้อ", sizeCode: "6w" },
//         { sizeName: "10 ล้อ", sizeCode: "10w" },
//     ];

//     const getUrlImagePicturePath = React.useCallback(async () => {
//         if (!imageBase64) return message.error("กรุณาใส่รูปภาพ");
//         let urlImage: string;
//         try {
//             urlImage = (
//                 (await uploadImageReturnUrl("images/lomsak", [
//                     await base64ToBlob(imageBase64),
//                 ])) as string[]
//             )[0];
//             return { picturePath: urlImage[0] };
//         } catch (error) {
//             setIsLoading(false);
//             return message.error("กรุณาลองใหม่อีกครั้ง");
//         }
//     }, [imageBase64]);

//     const getBuildingLomSakStorage = React.useCallback(async () => {
//         setIsLoading(true);
//         try {
//             const buildingStorage = await getBuildingMonthly();
//             setBuildingStorage(buildingStorage);
//             console.log(buildingStorage);
//         } catch (error) {
//             console.log({ error });
//         } finally {
//             setIsLoading(false);
//         }
//     }, []);

//     React.useEffect(() => {
//         const fetch = async () => await getBuildingLomSakStorage();
//         fetch();
//     }, [getBuildingLomSakStorage]);

    

//     const onSubmitApplicationLomSak = React.useCallback(async () => {
//         const picturePath = await getUrlImagePicturePath();
//         // const contract: LomsakType = {
//         //     picturePath,
//         //     status: "active",
//         //     type: carTypeSelect,
//         //     firstName: form.getFieldValue("firstName"),
//         //     lastName: form.getFieldValue("lastName"),
//         //     ssn: form.getFieldValue("ssn"),
//         //     tel: form.getFieldValue("phoneNumber"),
//         //     booth: [buildingSelect!,boothSelect! ],
//         //     size: carSizeSelect,
//         //     address:{
//         //         address: form.getFieldValue("address"),
//         //         province: form.getFieldValue("province"),
//         //         district: form.getFieldValue("district"),
//         //         subDistrict: form.getFieldValue("subDistrict"),
//         //         postCode: form.getFieldValue("zipCode"),
//         //     },
//         //     vaccine: numVaccines ? Array.from({ length: numVaccines }, () => ({

//         //     })) : [],

//         // }
        
//     }, []);

//     React.useEffect(()=>{

       

//     },[])

    
//     const onSubmit = (values: any) => {
//         // TODO Validate Image and check how to send data
        
//         // if (!imageBase64) return;
//         // setBoothShirt({ ...values, imageBase64 });
//         console.log(values);
//         // const unixData = dayjs(+values["date"][0]).unix() * 1000;
//         // console.log(unixData);
//     };
   

//     const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
//         if (info.file.status === "uploading") {
//             setImageBase64(undefined);
//             setImageError(undefined);
//             return setLoadingImage(true);
//         } else {
//             const src = await antdFileToBase64(info.file);
//             if (!src?.includes("data:image")) {
//                 setImageError("กรุณาอัพโหลดไฟล์รูป");
//                 message.error("กรุณาอัพโหลดไฟล์รูป");
//             } else setImageBase64(src);
//         }

//         setLoadingImage(false);
//     }, []);

//     const uploadButton = React.useMemo(
//         () => (
//             <div>
//                 {loadingImage ? (
//                     <LoadingOutlined />
//                 ) : (
//                     <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
//                 )}
//                 <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
//                     {imageError || "เพิ่มรูป"}
//                 </div>
//             </div>
//         ),
//         [loadingImage, imageError]
//     );

//     return (
//         <Container>
//             <Form form={form} onFinish={onSubmit}>
//                 {/* <Row justify="center">
//                     <CustomUpload
//                         error={imageError}
//                         name="avatar"
//                         listType="picture-card"
//                         showUploadList={false}
//                         onChange={onChangeImage}
//                     >
//                         {imageBase64 ? (
//                             <img src={imageBase64} alt="avatar" style={{ width: "100%" }} />
//                         ) : (
//                             uploadButton
//                         )}
//                     </CustomUpload>
//                 </Row>

//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "firstName",
//                             label: "ชื่อ",
//                             rules: [{ required: true, message: "กรุณากรอกชื่อ", whitespace: true }],
//                             placeholder: "กรุณากรอกชื่อ",
//                         },
//                         {
//                             name: "lastName",
//                             label: "นามสกุล",
//                             rules: [{ required: true, message: "กรุณากรอกนามสกุล" }],
//                             placeholder: "กรุณากรอกนามสกุล",
//                         },
//                     ]}
//                 />
//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "ssn",
//                             label: "รหัสประจำตัวประชาชน",

//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณากรอกรหัสประจำตัวประชาชน",
//                                     ...validator.ssn,
//                                 },
//                             ],
//                             placeholder: "กรุณากรอกรหัสประจำตัวประชาชน",
//                         },
//                     ]}
//                 />
//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "phoneNumber",
//                             label: "เบอร์โทรศัพท์",
//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณากรอกเบอร์โทรศัพท์",
//                                     ...validator.phoneNumber,
//                                 },
//                             ],
//                             placeholder: "กรุณากรอกเบอร์โทรศัพท์",
//                         },
//                     ]}
//                 />
//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "building",
//                             label: "อาคาร",
//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณาเลือกอาคาร",
//                                 },
//                             ],
//                             customInput: (
//                                 <Select onChange={setBuildingSelect}>
//                                     {Object.keys(buildingStorage).map((item) => (
//                                         <Option children={item} key={item} />
//                                     ))}
//                                 </Select>
//                             ),
//                         },
//                         {
//                             name: "booth",
//                             label: "เลขแผง",
//                             flex: 2,
//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณาเลือกเลขแผง",
//                                 },
//                             ],
//                             customInput: (
//                                 <Select
//                                     notFoundContent={!buildingSelect && "กรุณาเลือกอาคาร"}
//                                     onChange={setBoothSelect}
//                                 >
//                                     {buildingSelect &&
//                                         buildingStorage[buildingSelect].map((item) => (
//                                             <Option
//                                                 children={item.boothName}
//                                                 key={item.boothCode}
//                                             />
//                                         ))}
//                                 </Select>
//                             ),
//                         },
//                     ]}
//                 />

//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "carType",
//                             label: "ประเภทรถ",
//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณาเลือกประเภทรถ",
//                                 },
//                             ],
//                             customInput: (
//                                 <Select onChange={setCarTypeSelect}>
//                                     {carType &&
//                                         carType.map((item) => (
//                                             <Option children={item.typeName} key={item.typeCode} />
//                                         ))}
//                                 </Select>
//                             ),
//                         },
//                         {
//                             name: "size",
//                             label: "ขนาด",
//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณาเลือกขนาดของรถ",
//                                 },
//                             ],
//                             customInput: (
//                                 <Select
//                                     notFoundContent={!carTypeSelect && "กรุณาเลือกประเภทรถ"}
//                                     onChange={setCarSizeSelect}
//                                 >
//                                     {carTypeSelect &&
//                                         carSize.map((item) => (
//                                             <Option children={item.sizeName} key={item.sizeCode} />
//                                         ))}
//                                 </Select>
//                             ),
//                         },
//                     ]}
//                 />

//                 <b>ที่อยู่</b>
//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "address",
//                             label: "ที่อยู่",
//                             rules: [{ required: true, message: "กรุณากรอกที่อยู่" }],
//                             placeholder: "กรุณากรอกที่อยู่",
//                         },
//                     ]}
//                 />
//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "province",
//                             label: "จังหวัด",
//                             rules: [{ required: true, message: "กรุณากรอกจังหวัด" }],
//                             placeholder: "กรุณากรอกจังหวัด",
//                         },
//                         {
//                             name: "district",
//                             label: "อำเภอ",
//                             rules: [{ required: true, message: "กรุณากรอกอำเภอ" }],
//                             placeholder: "กรุณากรอกอำเภอ",
//                         },
//                     ]}
//                 />
//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "subDistrict",
//                             label: "ตำบล",
//                             rules: [{ required: true, message: "กรุณากรอกตำบล" }],
//                             placeholder: "กรุณากรอกตำบล",
//                         },
//                         {
//                             name: "zipCode",
//                             label: "รหัสไปรษณีย์",
//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณากรอกรหัสไปรษณีย์",
//                                     ...validator.zipCode,
//                                 },
//                             ],
//                             placeholder: "กรุณากรอกรหัสไปรษณีย์",
//                         },
//                     ]}
//                 />
//                 {xs ? (
//                     <Row gutter={24}>
//                         <Col span={20}>
//                             <b>ประวัติการฉีดวัคซีน</b>
//                         </Col>
//                         <Button
//                             icon={<PlusCircleOutlined />}
//                             size={"small"}
//                             onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
//                         ></Button>
//                     </Row>
//                 ) : (
//                     <Row gutter={24}>
//                         <Col span={6}>
//                             <b>ประวัติการฉีดวัคซีน</b>
//                         </Col>
//                         <Button
//                             icon={<PlusCircleOutlined />}
//                             size={"small"}
//                             onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
//                         >
//                             เพิ่มวัคซีน
//                         </Button>
//                     </Row>
//                 )} */}

//                 {Array.from(Array(numVaccines).keys()).map((index) => (
//                     <RowGroupInput
//                         key={index}
//                         inputs={[
//                             {
//                                 name: `vaccine${index}`,
//                                 label: "วัคซีน",
//                                 rules: [{ required: true, message: "กรุณากรอกวัคซีน" }],
//                                 placeholder: "กรุณากรอกวัคซีน",
//                             },
//                             {
//                                 name: `vaccineDate${index}`,
//                                 label: "วันที่ฉีด",
//                                 type: "calendar",
//                                 rules: [{ required: true, message: "กรุณากรอกวันที่ฉีด" }],
//                                 placeholder: "กรุณากรอกวันที่ฉีด",
//                             },
//                         ]}
//                         rightExtra={
//                             index === numVaccines - 1 && (
//                                 <MinusCircleOutlined
//                                     style={{
//                                         alignSelf: "center",
//                                         marginTop: "10px",
//                                         fontSize: "32",
//                                         color: "red",
//                                     }}
//                                     onClick={() => {
//                                         setVaccines((numVaccines) => numVaccines - 1);
//                                     }}
//                                 />
//                             )
//                         }
//                     />
//                 ))}
//                 <b>ข้อมูลผู้เกี่ยวข้อง</b>
//                 {/* <RowGroupInput
//                     inputs={[
//                         {
//                             name: "relativeFirstName",
//                             label: "ชื่อ",
//                             rules: [{ required: true, message: "กรุณากรอกชื่อ" }],
//                             placeholder: "กรุณากรอกชื่อ",
//                         },
//                         {
//                             name: "relativeLastName",
//                             label: "นามสกุล",
//                             rules: [{ required: true, message: "กรุณากรอกนามสกุล" }],
//                             placeholder: "กรุณากรอกนามสกุล",
//                         },
//                     ]}
//                 />
//                 <RowGroupInput
//                     inputs={[
//                         {
//                             name: "relativePhoneNumber",
//                             label: "เบอร์โทรศัพท์",
//                             rules: [
//                                 {
//                                     required: true,
//                                     message: "กรุณากรอกเบอร์โทรศัพท์",
//                                 },
//                                 {
//                                     pattern: /^[0-9]*$/,
//                                     message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
//                                 },
//                             ],
//                             placeholder: "กรุณากรอกเบอร์โทรศัพท์",
//                         },
//                     ]}
//                 />

//                 {xs ? (
//                     <RowGroupInput
//                         inputs={[
//                             {
//                                 name: "date",
//                                 label: "วันที่ลงทะเบียน ",
//                                 rules: [
//                                     {
//                                         required: true,
//                                         message: "กรุณาเลือกวันเริ่ม/สิ้นสุด สัญญา",
//                                     },
//                                 ],
//                                 customInput: (
//                                     <RangePicker
//                                         style={{ width: "100%", margin: "0 auto" }}
//                                         placeholder={["วันที่เริ่ม", "วันที่สิ้นสุด"]}
//                                     />
//                                 ),
//                             },
//                         ]}
//                     />
//                 ) : (
//                     <RowGroupInput
//                         inputs={[
//                             {
//                                 name: "date",
//                                 label: "วันที่ลงทะเบียน ",
//                                 rules: [
//                                     {
//                                         required: true,
//                                         message: "กรุณาเลือกวันเริ่ม/สิ้นสุด สัญญา",
//                                     },
//                                 ],
//                                 customInput: (
//                                     <RangePicker
//                                         style={{ width: "50%", margin: "0 auto" }}
//                                         placeholder={["วันที่เริ่ม", "วันที่สิ้นสุด"]}
//                                     />
//                                 ),
//                             },
//                         ]}
//                     />
//                 )} */}
//                 <Row justify="center">
//                     <Button type="primary" htmlType="submit" size={"large"}>
//                         บันทึกการลงทะเบียน
//                     </Button>
//                 </Row>
//             </Form>
//         </Container>
//     );
// };

// export default RegisterLomSak;


function Lomsak() {
  return (
    <div>Lomsak</div>
  )
}

export default Lomsak