import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layouts from "./layouts";
import { ROUTES, converter } from "./layouts/routes";
import { useLayoutContext } from "./providers/LayoutProvider";
import * as firebase from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

////////// FIREBASE CONFIG //////////
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.getApps().length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.getApps(); // if already initialized, use that one
}

if (window.location.hostname === "localhost") {
  const authInstance = getAuth();
  connectAuthEmulator(authInstance, "http://localhost:9099");
}


const App = () => {
    const routes = converter(ROUTES());
    const { hideLayout, showLayout, isShowLayout } = useLayoutContext();
    const { pathname } = useLocation();
    React.useEffect(() => {
        const route = routes.find(
            (route) => pathname.includes(route.path) || pathname === route.path
        );
        if (!route) return hideLayout();
        if (route.isHideLayout) return hideLayout();
        else return showLayout();
    }, [pathname, routes, hideLayout, showLayout]);
    React.useEffect(() => {
        console.log({ isShowLayout });
    }, [isShowLayout]);
    return (
        <div className="App">
            <Layouts>
                <Routes>
                    {routes.map((route) => {
                        if (route.isAcceptRoles === false) return null;
                        return (
                            <Route key={route.path} path={route.path} element={route.component} />
                        );
                    })}
                </Routes>
            </Layouts>
        </div>
    );
};

export default App;
