import React from "react";
import { Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import SearchInput from "../../../../components/common/SearchInput";
import CardMember from "../../../../components/MemberManagementPage/Staff/CardMember";
import {
    CardMemberContainer,
    DetailLabel,
    DetailLabelContainer,
    HeaderContainer,
} from "./index.style";
import useMemberDetail, { MemberDetailContext, listColorDetail } from "./useMemberDetail";
import ModalUpdateWorkList from "../../../../components/MemberManagementPage/Staff/ModalUpdateWorkList";

function MemberDetail() {
    const memberDetail = useMemberDetail();
    const [keySearch, setKeySearch] = React.useState("");
    const { memberList, handleSearch, isLoading } = memberDetail;
    const handleChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) =>
        setKeySearch(event.target.value);

    return (
        <MemberDetailContext.Provider value={memberDetail}>
            <Content>
                <ModalUpdateWorkList />
                <HeaderContainer>
                    <DetailLabelContainer>
                        {listColorDetail.map((item) => (
                            <DetailLabel key={`${item.key}-label`} color={item.color}>
                                {item.title}
                            </DetailLabel>
                        ))}
                    </DetailLabelContainer>
                    <SearchInput
                        placeholder={"ค้นหาด้วย ชื่อ / อีเมล"}
                        onChange={handleChangeSearch}
                    />
                </HeaderContainer>
                <Spin tip="Loading..." spinning={isLoading}>
                    <CardMemberContainer>
                        {handleSearch(keySearch, memberList).map((item, key) => (
                            <CardMember key={`${item.name}-${key}`} {...item} />
                        ))}
                    </CardMemberContainer>
                </Spin>
            </Content>
        </MemberDetailContext.Provider>
    );
}

export default MemberDetail;
