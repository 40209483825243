import { Layout } from "antd";
import styled from "styled-components";
import colors from "../assets/colors.json";

import MoreMenuIcon from "../assets/icons/MoreMenuIcon";

export const { Header, Sider, Content } = Layout;

export const AppLayout = styled(Layout)`
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
`;

export const ContentLayout = styled(Layout)`
    background: #fff;
`;
export const AppContent = styled(Content)`
    padding: 1rem;
    background: ${colors.background};
    overflow: auto;
`;

export const AppHeader = styled(Header)`
    display: flex;
    padding: 0 1.5rem;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    align-items: center;
`;

export const Avatar = styled.div`
    background-color: #ddd;
    width: 32px;
    height: 32px;
    border-radius: 9999px;
`;

export const MenuIcon = styled(MoreMenuIcon)`
    transform: scale(0.75);
    cursor: pointer;
`;
