import { Button, Form, Modal, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import BoothCardComponent from "./BoothCardComponent";

type IBoothPrice = {
    boothList: any[];
    visible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
};

function ModalCardManage(props: IBoothPrice) {
    const { boothList, visible, handleOk, handleCancel } = props;
    const [edit, setEdit] = React.useState<boolean>(false);
    const [form] = Form.useForm();
    const [recordRow, setRecordRow] = React.useState<any>({});

    const TriggerEdit = () => {
        !edit && setEdit(!edit);
        edit && form.submit();
    };

    const [numBooth, setnumBooth] = React.useState<number>(
        boothList && boothList.length > 0 ? boothList.length : 1
    );

    const onAddSubBooth = () => {
        setnumBooth((numBooths) => numBooths + 1);
        console.log(numBooth);
    };

    const deleteSubBooth = () => {
        setnumBooth((numBooths) => numBooths - 1);
    };

    React.useEffect(() => {
        setnumBooth(boothList && boothList.length > 0 ? boothList.length : 1);
    }, [setnumBooth, boothList, edit]);

    const onCancelEdit = () => {
        setEdit(!edit);
        showFormValue(recordRow);
    };

    const showFormValue = (record: any) => {};

    return (
        <>
            <Modal
                title="ราคาแผงรายเดือน อาคาร แผง"
                footer={null}
                visible={visible}
                onCancel={handleCancel}
            >
                <Row justify="space-between">
                    <h2>รายการค่าเช่า</h2>
                    <Button type="primary" onClick={onAddSubBooth}>
                        <PlusOutlined style={{ fontSize: "16px" }} /> แบ่งแผง
                    </Button>
                </Row>
                <BoothCardComponent
                    boothList={boothList}
                    edit={edit}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    onTriggerEdit={TriggerEdit}
                    onCancelEdit={onCancelEdit}
                    numBooth={numBooth}
                    deleteSubBooth={deleteSubBooth}
                />
            </Modal>
        </>
    );
}

export default ModalCardManage;
