import { PageHeader } from "antd";
import React from "react";
import { Container, HeaderContainer, SubRouter } from "./index.style";
import MemberDetail from "./MemberDetail";
import WorkManagement from "./WorkManagement";

type SubRouteProps = {
    title: string;
    value: string;
    component: React.ReactNode;
};

export const MemberManagementStaffSubRoute: SubRouteProps[] = [
    {
        title: "รายละเอียดสมาชิก",
        value: "memberDetail",
        component: <MemberDetail />,
    },
    {
        title: "จัดการงาน",
        value: "workManagement",
        component: <WorkManagement />,
    },
];

function MemberManagementStaff() {
    const [subRoute, setSubRoute] = React.useState<SubRouteProps>(MemberManagementStaffSubRoute[0]);

    const classNameSubRoute = (sRoute: SubRouteProps) =>
        sRoute.value === subRoute.value ? "active" : "";

    const onChangeSubRoute = (sRoute: SubRouteProps) => () => setSubRoute(sRoute);

    return (
        <Container>
            <HeaderContainer>
                <PageHeader title="จัดการพนักงาน" />
                <SubRouter>
                    {MemberManagementStaffSubRoute.map((sRoute) => (
                        <span
                            key={sRoute.value}
                            className={classNameSubRoute(sRoute)}
                            onClick={onChangeSubRoute(sRoute)}
                        >
                            {sRoute.title}
                        </span>
                    ))}
                </SubRouter>
            </HeaderContainer>
            {subRoute.component}
        </Container>
    );
}

export default MemberManagementStaff;
