import React from "react";
import { User } from "../interfaces/User";
// import { timeout } from "../functions/delay";
import { showNotification } from "../functions/notification";
import axios, { AxiosError } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { FirebaseError } from "firebase/app";
// import axios from "axios";

// const mockUser: User = {
//     roles: ["admin"],
//     firstName: "Rhianna",
//     lastName: "Santos",
//     email: "rboxenf@jugem.jp",
//     tel: "087-837-8982",
//     dutyPool: ["booth","boothShirt", "meterBooth"],
//     subDuty: ["boothShirt", "meterBooth"],
//     mainDuty: "parking",
//     ssn: "9473798105211",
// };

const AuthContext = React.createContext<{
    user?: User;
    refreshUserData: () => void;
    login: () => void;
    logout: () => void;
    isLoading: boolean;
    toggleRole: (role: string) => void;
}>({
    refreshUserData: () => {},
    login: () => {},
    logout: () => {},
    isLoading: false,
    toggleRole: () => {},
});

function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<User>();
    // const [credential, setCredential] = React.useState<UserCredential>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    // TODO: Remove mockup User when deploy!!! //
    // React.useEffect(() => {
    //     if (window.location.hostname === "localhost") {
    //         setUser(mockUser);
    //         navigate(location.pathname + location.search, {
    //             replace: true,
    //             state: { from: location },
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const refreshUserData = React.useCallback(async (role?: string[]) => {
        setIsLoading(true);
        try {
            const userData = await axios.get<User>("/users/detail");
            if (!userData) throw new Error("User data is empty");
            userData.data.roles = [
                ...(role ? role : []),
                userData.data.mainDuty,
                ...userData.data.subDuty,
            ];
            setUser((user) => (userData.data ? { ...user, ...userData.data } : undefined));
            navigate("/dashboard");
        } catch (error) {
            const err = error as AxiosError;
            showNotification(err.response?.data.message, "error");
            localStorage.removeItem("token");
            axios.defaults.headers.common = { Authorization: "" };
            navigate("/login");
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const token = localStorage.getItem("token");
        const roleLocal = localStorage.getItem("role") as string;
        // const role = JSON.parse(roleLocal);
        const getUserData = async () => {
            axios.defaults.headers.common = {
                Authorization: `Bearer ${token}`,
            };
            await refreshUserData();
            navigate(location.pathname + location.search, {
                replace: true,
                state: { from: location },
            });
        };
        if (token) getUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = React.useCallback(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        axios.defaults.headers.common = { Authorization: "" };
        setUser(undefined);
        navigate("/login");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleRole = React.useCallback((role: string) => {
        setUser((user) => (user ? { ...user, roles: [role] } : undefined));
    }, []);

    const login = React.useCallback(async () => {
        const authGoogle = getAuth();
        const googleProvider = new GoogleAuthProvider();
        try {
            const res = await signInWithPopup(authGoogle, googleProvider);
            // setCredential(res);
            const token = await res.user?.getIdToken();
            localStorage.setItem("token", token);
            const customClaim = await (await res.user?.getIdTokenResult()).claims;
            const role = customClaim.role as string[];
            axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
            localStorage.setItem("role", JSON.stringify(role));
            await refreshUserData(role);
        } catch (err) {
            if (err instanceof FirebaseError) {
                return showNotification(err.code, "error");
            }
            return showNotification("เกิดข้อผิดพลาดบางประการ", "error");
        }
    }, [refreshUserData]);
    return (
        <AuthContext.Provider
            value={{
                user,
                refreshUserData,
                logout,
                isLoading,
                toggleRole,
                login,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuthContext = () => React.useContext(AuthContext);
export default AuthProvider;
