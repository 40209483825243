import { Collapse } from "antd";
import styled from "styled-components";
import colors from "../../assets/colors.json";

type IBackground = {
    state: string;
};

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const SubRoute = styled.div`
    flex: 1;
    display: flex;
    padding: 0 3rem;
`;

export const SubComponent = styled.section`
    flex: 1;
    padding: 1.5rem 1rem;
`;

export const CircleState = styled.div`
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-left: auto;
    background-color: ${(props: IBackground) =>
        props.state === "active" ? colors.memberDetailWorkColor.mainDuty : "#FFF"};
`;

export const CollapseFullWidth = styled(Collapse)`
    width: 100%;
`;

export const ContainerSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    width: 100%;
`