import { Avatar } from "antd";
import React from "react";
import logo from "../assets/images/smt-logo.png";
import { useLayoutContext } from "../providers/LayoutProvider";
import AppSidebar from "./AppSideBar";
import { AppContent, AppHeader, AppLayout, ContentLayout, MenuIcon } from "./index.style";
import color from "../assets/colors.json";

function Layouts(props: { children: React.ReactElement }) {
    const [collapsed, setCollapsed] = React.useState(false);
    const toggle = () => setCollapsed((collapse) => !collapse);
    const { isShowLayout } = useLayoutContext();

    return isShowLayout ? (
        <AppLayout>
            <AppHeader>
                <MenuIcon onClick={toggle} color={color.primary} />
                <img src={logo} alt="logo" height={42} />
                <Avatar />
            </AppHeader>
            <ContentLayout>
                <AppSidebar collapsed={collapsed} />
                <AppContent>{props.children}</AppContent>
            </ContentLayout>
        </AppLayout>
    ) : (
        props.children
    );
}

export default Layouts;
