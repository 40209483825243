import React from "react";
import { Result, Button } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { downloadContractPromise } from "../../../../apis/register";

type SuccessProps = {
    firstName?: string;
    lastName?: string;
    contractCode: string;
};

const Success: React.FC<SuccessProps> = ({ firstName, lastName, contractCode }) => {
    return (
        <Result
            status="success"
            title="ดำเนินการเสร็จสิ้น"
            subTitle={`เอกสารสัญญาคุณ 
            ${firstName || "ชื่อผู้เช้า"} 
            ${lastName || "นามสกุลผู้เช้า"} `}
            extra={
                <Button
                    type="primary"
                    onClick={() => downloadContractPromise(contractCode, firstName)}
                    icon={<FileTextOutlined />}
                >
                    สัญญาเช่าพื้นที่ pdf
                </Button>
            }
        />
    );
};

export default Success;
