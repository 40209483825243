import styled from "styled-components";
import colors from "../../../assets/colors.json";

export const NumberInputContainer = styled.div`
    display: flex;
    position: relative;
    width: 64px;
    height: 20px;
    background-color: ${colors.primary}55;
    overflow: hidden;
    border-radius: 4px;
`;

export const NumberInputContent = styled.input.attrs({
    type: "number",
})`
    flex: 1;
    max-width: calc(100% - 40px);
    max-height: 100%;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    text-align: center;
    border: none;
    border-radius: 4px;
`;

export const NumberButton = styled.div`
    cursor: pointer;
    height: 100%;
    background-color: ${colors.primary}55;
    color: white;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* KHTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: ${colors.primary};
    }
`;
