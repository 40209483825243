import { Input, Dropdown, Menu } from "antd";
import { FormItem } from "../../../../components/MemberManagementPage/ContractMember/ModalComponents/VaccineTimeline/index.styles";
import { EllipsisOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
    CancleButton,
    ConfirmButton,
    Container,
    EditContainer,
    RowFooter,
    RowContainer,
    DropdownButton,
} from "./index.styles";

type IBoothCard = {
    boothList: any[];
    handleOk: () => void;
    handleCancel: () => void;
    onTriggerEdit: () => void;
    onCancelEdit: () => void;
    deleteSubBooth: () => void;
    edit: boolean;
    numBooth: number;
};

function BoothCardComponent(props: IBoothCard) {
    const { handleOk, handleCancel, onCancelEdit, onTriggerEdit, edit, numBooth, deleteSubBooth } =
        props;

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <a href="#" target="_blank" onClick={onTriggerEdit} rel="noopener noreferrer">
                    <EditOutlined style={{ fontSize: "16px" }} /> แก้ไขแผง
                </a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href="#" target="_blank" onClick={deleteSubBooth} rel="noopener noreferrer">
                    <DeleteOutlined style={{ fontSize: "16px" }} /> ลบแผง
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {!edit ? (
                <>
                    {Array(numBooth)
                        .fill(null)
                        .map((_, index) => (
                            <div key={index}>
                                <Container>
                                    <h2>แผง #</h2>
                                    <EditContainer>
                                        <Dropdown
                                            overlay={menu}
                                            trigger={["click"]}
                                            placement="bottomRight"
                                            arrow
                                        >
                                            <DropdownButton>
                                                <EllipsisOutlined style={{ fontSize: "20px" }} />
                                            </DropdownButton>
                                        </Dropdown>
                                    </EditContainer>
                                </Container>
                                <RowContainer>
                                    <h3>ราคาแผง</h3>
                                    <h3>฿</h3>
                                </RowContainer>
                                <RowContainer>
                                    <h3>ค่ารายปี</h3>
                                    <h3>฿</h3>
                                </RowContainer>
                            </div>
                        ))}
                    <RowFooter>
                        <CancleButton onClick={handleOk}>
                            <span> ยกเลิก</span>
                        </CancleButton>
                        <ConfirmButton onClick={handleCancel}>
                            <span> ตกลง</span>
                        </ConfirmButton>
                    </RowFooter>
                </>
            ) : (
                <>
                    {Array(numBooth)
                        .fill(null)
                        .map((_, index) => (
                            <div key={index}>
                                <Container>
                                    <h2>แผง#</h2>
                                </Container>
                                <RowContainer>
                                    <h3>ราคาแผง</h3>
                                    <FormItem
                                        name={`kk`}
                                        rules={[
                                            {
                                                required: true,
                                                message: "กรุณาระบุราคา",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="$" />
                                    </FormItem>
                                </RowContainer>
                                <RowContainer>
                                    <h3>ค่ารายปี</h3>
                                    <FormItem
                                        name={`kk`}
                                        rules={[
                                            {
                                                required: true,
                                                message: "กรุณาระบุราคา",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="$" />
                                    </FormItem>
                                </RowContainer>
                            </div>
                        ))}
                    <RowFooter>
                        <CancleButton onClick={onCancelEdit}>
                            <span> ยกเลิก</span>
                        </CancleButton>
                        <ConfirmButton onClick={handleCancel}>
                            <span> ยืนยันการแก้ไข</span>
                        </ConfirmButton>
                    </RowFooter>
                </>
            )}
        </>
    );
}
export default BoothCardComponent;
