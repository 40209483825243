import { Button, Col, Input, Row } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { FormItem, RowGroupInput } from "../../../common/CustomForm";
import React from "react";
import validator from "../../../../assets/formValidator";

type MemberFormProps = {
    memIndex?: number;
    numMembers?: number;
    form: FormInstance<any>;
    popMember?: () => void;
    initVaccines?: number;
};

const MemberForm: React.FC<MemberFormProps> = (props) => {
    const [numVaccines, setVaccines] = React.useState<number>(props.initVaccines || 1);

    React.useEffect(() => {
        props.form &&
            props.form.setFields([
                {
                    name: `numVaccines${props.memIndex}`,
                    value: numVaccines,
                },
            ]);
    }, [numVaccines, props.form, props.memIndex]);

    return (
        <>
            <RowGroupInput
                inputs={[
                    {
                        name: `firstName${props.memIndex}`,
                        label: "ชื่อ",
                        rules: [{ required: true, message: "กรุณากรอกชื่อ" }],
                        placeholder: "กรุณากรอกชื่อ",
                    },
                    {
                        name: `lastName${props.memIndex}`,
                        label: "นามสกุล",
                        rules: [{ required: true, message: "กรุณากรอกนามสกุล" }],
                        placeholder: "กรุณากรอกนามสกุล",
                    },
                ]}
            />
            <RowGroupInput
                inputs={[
                    {
                        name: `ssn${props.memIndex}`,
                        label: "รหัสประจำตัวประชาชน",
                        rules: [
                            { required: true, message: "กรุณากรอกรหัสประจำตัวประชาชน" },
                            ...validator.ssn,
                        ],
                        placeholder: "กรุณากรอกรหัสประจำตัวประชาชน",
                    },
                ]}
            />
            <RowGroupInput
                inputs={[
                    {
                        name: `phoneNumber${props.memIndex}`,
                        label: "เบอร์โทรศัพท์",
                        rules: [
                            {
                                required: true,
                                message: "กรุณากรอกเบอร์โทรศัพท์",
                            },
                            ...validator.phoneNumber,
                        ],
                        placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                    },
                ]}
            />
            <b>ที่อยู่</b>
            <RowGroupInput
                inputs={[
                    {
                        name: `address${props.memIndex}`,
                        label: "ที่อยู่",
                        rules: [{ required: true, message: "กรุณากรอกที่อยู่" }],
                        placeholder: "กรุณากรอกที่อยู่",
                    },
                ]}
            />
            <RowGroupInput
                inputs={[
                    {
                        name: `province${props.memIndex}`,
                        label: "จังหวัด",
                        rules: [{ required: true, message: "กรุณากรอกจังหวัด" }],
                        placeholder: "กรุณากรอกจังหวัด",
                    },
                    {
                        name: `district${props.memIndex}`,
                        label: "อำเภอ",
                        rules: [{ required: true, message: "กรุณากรอกอำเภอ" }],
                        placeholder: "กรุณากรอกอำเภอ",
                    },
                ]}
            />
            <RowGroupInput
                inputs={[
                    {
                        name: `subDistrict${props.memIndex}`,
                        label: "ตำบล",
                        rules: [{ required: true, message: "กรุณากรอกตำบล" }],
                        placeholder: "กรุณากรอกตำบล",
                    },
                    {
                        name: `zipCode${props.memIndex}`,
                        label: "รหัสไปรษณีย์",
                        rules: [
                            { required: true, message: "กรุณากรอกรหัสไปรษณีย์" },
                            ...validator.zipCode,
                        ],
                        placeholder: "กรุณากรอกรหัสไปรษณีย์",
                    },
                ]}
            />

            <Row gutter={24}>
                <Col span={6}>
                    <b>ประวัติการฉีดวัคซีน</b>
                    <FormItem name={"numVaccines" + props.memIndex} style={{ display: "none" }}>
                        <Input />
                    </FormItem>
                </Col>
                <Button
                    icon={<PlusCircleOutlined />}
                    size={"small"}
                    onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
                >
                    เพิ่มวัคซีน
                </Button>
            </Row>

            {Array.from(Array(numVaccines).keys()).map((index) => (
                <RowGroupInput
                    key={index}
                    inputs={[
                        {
                            name: `vaccine${props.memIndex}${index}`,
                            label: "วัคซีน",
                            rules: [{ required: true, message: "กรุณากรอกวัคซีน" }],
                            placeholder: "กรุณากรอกวัคซีน",
                        },
                        {
                            name: `vaccineDate${props.memIndex}${index}`,
                            label: "วันที่ฉีด",
                            rules: [{ required: true, message: "กรุณากรอกวันที่ฉีด" }],
                            placeholder: "กรุณากรอกวันที่ฉีด",
                            type: "calendar",
                        },
                    ]}
                    rightExtra={
                        index === numVaccines - 1 && (
                            <MinusCircleOutlined
                                style={{
                                    alignSelf: "center",
                                    marginTop: "10px",
                                    fontSize: "32",
                                    color: "red",
                                }}
                                onClick={() => {
                                    setVaccines((numVaccines) => numVaccines - 1);
                                }}
                            />
                        )
                    }
                />
            ))}
        </>
    );
};

export default MemberForm;
