import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.variable.min.css";
import { BrowserRouter } from "react-router-dom";
import Provider from "./providers";
import axios from "axios";
import { ConfigProvider } from "antd";
import thTH from "antd/lib/locale/th_TH";
import dayjs from "dayjs";
import "dayjs/locale/th";
import buddhaEra from "dayjs/plugin/buddhistEra";

import * as firebase from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";

dayjs.extend(buddhaEra);
dayjs.locale("th");
////////// AXIOS CONFIG //////////
if (window.location.hostname === "localhost")
    axios.defaults.baseURL = "http://localhost:5001/rooz-smt/asia-southeast1/apiV1";
else axios.defaults.baseURL = process.env.REACT_APP_API_URL;
////////// //////////// //////////

////////// FIREBASE CONFIG //////////
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.getApps().length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.getApps(); // if already initialized, use that one
}

if (window.location.hostname === "localhost") {
    const authInstance = getAuth();
    connectAuthEmulator(authInstance, "http://localhost:9099");
    const storageInstance = getStorage();
    connectStorageEmulator(storageInstance, "localhost", 9199);
}
////// //////////////// //////////

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigProvider locale={thTH}>
                <Provider>
                    <App />
                </Provider>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
