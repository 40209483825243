import React from "react";
import ApplicationThemeProvider from "./ApplicationThemeProvider";
import AuthProvider from "./AuthProvider";
import LayoutProvide from "./LayoutProvider";
import NotificationContext from "./NotificationProvider";

function Provider(props: { children: React.ReactNode }) {
    return (
        <AuthProvider>
            <NotificationContext>
                <ApplicationThemeProvider>
                    <LayoutProvide>{props.children}</LayoutProvide>
                </ApplicationThemeProvider>
            </NotificationContext>
        </AuthProvider>
    );
}

export default Provider;
