import { Typography } from "antd";
import {
    Container,
    FormItem,
    InputItem,
    RowContainer,
    RowInputContainer,
} from "./index.styles";

type IPerson = {
    edit: boolean;
};

function PersonRelated(props: IPerson) {
    const { edit } = props;
    const Paragraph = Typography.Paragraph;


    return (
        <Container>
            <RowContainer>
                <Paragraph strong>ผู้เกี่ยวข้องที่สามารถติดต่อได้</Paragraph>
            </RowContainer>
            
            {!edit ? (
                <RowInputContainer>
                    <FormItem name={`firstNameInvolved`}>
                        <InputItem readOnly />
                    </FormItem>
                    <FormItem name={`lastNameInvolved`}>
                        <InputItem readOnly />
                    </FormItem>
                    <FormItem name={`telInvolved`}>
                        <InputItem readOnly />
                    </FormItem>
                </RowInputContainer>
            ) : (
                <RowInputContainer>
                    <FormItem
                        name="firstNameInvolved"
                        label={`ชื่อ`}
                        rules={[
                            {
                                required: true,
                                message: "กรุณากรอกชื่อ",
                            },
                        ]}
                    >
                        <InputItem readOnly={!edit} editable={`${edit}`} placeholder="ชื่อ" />
                    </FormItem>
                    <FormItem
                        name="lastNameInvolved"
                        label={`นามสกุล`}
                        rules={[
                            {
                                required: true,
                                message: "กรุณากรอกนามสกุล",
                            },
                        ]}
                    >
                        <InputItem readOnly={!edit} editable={`${edit}`} placeholder="นามสกุล" />
                    </FormItem>
                    <FormItem
                        name="telInvolved"
                        label={`เบอร์โทรศัพท์`}
                        rules={[
                            {
                                required: true,
                                message: "กรุณากรอกเบอร์โทรศัพท์",
                            },
                        ]}
                    >
                        <InputItem
                            readOnly={!edit}
                            editable={`${edit}`}
                            placeholder="เบอร์โทรศัพท์"
                        />
                    </FormItem>
                </RowInputContainer>
            )}
        </Container>
    );
}
export default PersonRelated;
