import { Container, ContentBackground, } from "../../../../components/common/CustomForm";
import RegisterRiderShirt from "../../../../components/RegisterPage/Shirt/RiderShirt"

function RegisterRiderShirtPage() {
    return (
        <Container>
            <ContentBackground>
                <h1>ลงทะเบียนเสื้อวิน</h1>
                <RegisterRiderShirt />

            </ContentBackground>
        </Container>
    );
}

export default RegisterRiderShirtPage;