// import { Button, Modal, Select } from "antd";
import styled from "styled-components";
// import colors from "../../../../assets/colors.json";
import CanvasDraw from "react-canvas-draw";

export const Container = styled.div`
    Form > h2 {
        text-align: center;
    }
    h4 > span {
        font-weight: bold;
    }
`;

export const CanvasContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    touch-action: none;
`;

export const CanvasSign = styled(CanvasDraw)`
    // Border in canvas
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.23);
    text-align: center;
    touch-action: pan-y !important;
`;

export const CheckboxContainer = styled.div`
    margin: 1rem 0 2rem 2rem;
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0 !important;
    }

    .ant-form-item {
        margin: 0 !important;
    }
`;
