import { Form } from "antd";
import React from "react";
import { editAlert, successAlert } from "../../../components/PriceManagementPage/alert";
import { catchingError } from "../../../functions/notification";
import { IPricing } from "../../../interfaces/PriceManagement";
import {
    getCarPrices,
    getShirtPrices,
    patchCarPrices,
    patchShirtPrices,
} from "../../../services/priceManagement";

function useShirtManagement() {
    const [data, setData] = React.useState<IPricing>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [form] = Form.useForm();

    const onResetForm = () => form.resetFields();

    // TODO Try Catch Exception
    const onFinish = async (res: IPricing) => {
        const newData: IPricing = {};
        Object.keys(res).forEach((key) => {
            if (data && res[key] !== data[key]) {
                newData[key] = res[key];
            }
        });
        const result = await editAlert(newData);
        if (result) {
            setIsLoading(true);
            let patchSwitch = false;
            Object.keys(newData).forEach(async (key) => {
                if (key.includes("Shirt")) {
                    patchSwitch = true;
                }
            });
            try {
                if (patchSwitch) {
                    await patchShirtPrices(newData);
                } else {
                    await patchCarPrices(newData);
                }
                fetchData();
                await successAlert();
            } catch (error) {
                catchingError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const shirtPrices = await getShirtPrices();
            const carPrices = await getCarPrices();
            setData({ ...shirtPrices, ...carPrices });
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    React.useEffect(() => {
        fetchData();
        return () => {
            fetchData();
        };
    }, [fetchData]);

    return {
        data,
        form,
        isLoading,
        onResetForm,
        onFinish,
    };
}

export default useShirtManagement;
