import React from "react";

const LayoutContext = React.createContext<{
    isShowLayout: boolean;
    hideLayout: () => void;
    showLayout: () => void;
}>({
    isShowLayout: true,
    hideLayout: () => {},
    showLayout: () => {},
});

function LayoutProvide({ children }: { children: React.ReactNode }) {
    const [isShowLayout, setIsShowLayout] = React.useState(false);
    const hideLayout = React.useCallback(() => setIsShowLayout(false), []);
    const showLayout = React.useCallback(() => setIsShowLayout(true), []);
    return (
        <LayoutContext.Provider
            value={{
                isShowLayout,
                hideLayout,
                showLayout,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
}

export const useLayoutContext = () => React.useContext(LayoutContext);
export default LayoutProvide;
