import { Button, Row, Form, Col, DatePicker, notification, message, Grid } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Container } from "../../../common/CustomForm";
import { CustomUpload } from "./index.style";
import { RowGroupInput } from "../../../common/CustomForm";
import { antdFileToBase64 } from "../../../../functions/files";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { ShirtType } from "../../../../interfaces/Register/Shirt";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import validator from "../../../../assets/formValidator";

type ShirtProps = {
    initVaccines?: number;
};

const RegisterBoothShirt: React.FC<ShirtProps> = (props) => {
    const { useBreakpoint } = Grid;
    const [form] = Form.useForm();
    const [boothShirt, setBoothShirt] = React.useState<ShirtType>();
    const [imageBase64, setImageBase64] = React.useState<string>();
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();
    const [numVaccines, setVaccines] = React.useState<number>(props.initVaccines || 1);
    const { RangePicker } = DatePicker;
    const { xs } = useBreakpoint();
    const onSubmit = (values: any) => {
        // Validate Image

        if (!imageBase64) return;
        setBoothShirt({ ...values, imageBase64 });
        console.log(boothShirt);
    };

    const onClickSubmit = () => {
        if (!imageBase64) {
            setImageError("กรุณาอัพโหลดรูปผู้เช่า");
            notification.error({ message: "กรุณาอัพโหลดรูปผู้เช่า" });
        }
    };

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                message.error("กรุณาอัพโหลดไฟล์รูป");
            } else setImageBase64(src);
        }

        setLoadingImage(false);
    }, []);

    const uploadButton = React.useMemo(
        () => (
            <div>
                {loadingImage ? (
                    <LoadingOutlined />
                ) : (
                    <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
                )}
                <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
                    {imageError || "เพิ่มรูป"}
                </div>
            </div>
        ),
        [loadingImage, imageError]
    );

    return (
        <Container>
            <Form form={form} onFinish={onSubmit}>
                <Row justify="center">
                    <CustomUpload
                        error={imageError}
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        onChange={onChangeImage}
                    >
                        {imageBase64 ? (
                            <img src={imageBase64} alt="avatar" style={{ width: "100%" }} />
                        ) : (
                            uploadButton
                        )}
                    </CustomUpload>
                </Row>

                <RowGroupInput
                    inputs={[
                        {
                            name: "firstName",
                            label: "ชื่อ",
                            rules: [{ required: true, message: "กรุณากรอกชื่อ", whitespace: true }],
                            placeholder: "กรุณากรอกชื่อ",
                        },
                        {
                            name: "lastName",
                            label: "นามสกุล",
                            rules: [{ required: true, message: "กรุณากรอกนามสกุล" }],
                            placeholder: "กรุณากรอกนามสกุล",
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "ssn",
                            label: "รหัสประจำตัวประชาชน",

                            rules: [
                                {
                                    required: true,
                                    message: "กรุณากรอกรหัสประจำตัวประชาชน",
                                    ...validator.ssn,
                                },
                            ],
                            placeholder: "กรุณากรอกรหัสประจำตัวประชาชน",
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "phoneNumber",
                            label: "เบอร์โทรศัพท์",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณากรอกเบอร์โทรศัพท์",
                                    ...validator.phoneNumber,
                                },
                            ],
                            placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                        },
                    ]}
                />
                <b>ที่อยู่</b>
                <RowGroupInput
                    inputs={[
                        {
                            name: "address",
                            label: "ที่อยู่",
                            rules: [{ required: true, message: "กรุณากรอกที่อยู่" }],
                            placeholder: "กรุณากรอกที่อยู่",
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "province",
                            label: "จังหวัด",
                            rules: [{ required: true, message: "กรุณากรอกจังหวัด" }],
                            placeholder: "กรุณากรอกจังหวัด",
                        },
                        {
                            name: "district",
                            label: "อำเภอ",
                            rules: [{ required: true, message: "กรุณากรอกอำเภอ" }],
                            placeholder: "กรุณากรอกอำเภอ",
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "subDistrict",
                            label: "ตำบล",
                            rules: [{ required: true, message: "กรุณากรอกตำบล" }],
                            placeholder: "กรุณากรอกตำบล",
                        },
                        {
                            name: "zipCode",
                            label: "รหัสไปรษณีย์",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณากรอกรหัสไปรษณีย์",
                                    ...validator.zipCode,
                                },
                            ],
                            placeholder: "กรุณากรอกรหัสไปรษณีย์",
                        },
                    ]}
                />
                {xs ? (
                    <Row gutter={24}>
                        <Col span={20}>
                            <b>ประวัติการฉีดวัคซีน</b>
                        </Col>
                        <Button
                            icon={<PlusCircleOutlined />}
                            size={"small"}
                            onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
                        ></Button>
                    </Row>
                ) : (
                    <Row gutter={24}>
                        <Col span={6}>
                            <b>ประวัติการฉีดวัคซีน</b>
                        </Col>
                        <Button
                            icon={<PlusCircleOutlined />}
                            size={"small"}
                            onClick={() => setVaccines((numVaccines) => numVaccines + 1)}
                        >
                            เพิ่มวัคซีน
                        </Button>
                    </Row>
                )}

                {Array.from(Array(numVaccines).keys()).map((index) => (
                    <RowGroupInput
                        key={index}
                        inputs={[
                            {
                                name: `vaccine${index}`,
                                label: "วัคซีน",
                                rules: [{ required: true, message: "กรุณากรอกวัคซีน" }],
                                placeholder: "กรุณากรอกวัคซีน",
                            },
                            {
                                name: `vaccineDate${index}`,
                                label: "วันที่ฉีด",
                                type: "calendar",
                                rules: [{ required: true, message: "กรุณากรอกวันที่ฉีด" }],
                                placeholder: "กรุณากรอกวันที่ฉีด",
                            },
                        ]}
                        rightExtra={
                            index === numVaccines - 1 && (
                                <MinusCircleOutlined
                                    style={{
                                        alignSelf: "center",
                                        marginTop: "10px",
                                        fontSize: "32",
                                        color: "red",
                                    }}
                                    onClick={() => {
                                        setVaccines((numVaccines) => numVaccines - 1);
                                    }}
                                />
                            )
                        }
                    />
                ))}
                <b>ข้อมูลผู้เกี่ยวข้อง</b>
                <RowGroupInput
                    inputs={[
                        {
                            name: "relativeFirstName",
                            label: "ชื่อ",
                            rules: [{ required: true, message: "กรุณากรอกชื่อ" }],
                            placeholder: "กรุณากรอกชื่อ",
                        },
                        {
                            name: "relativeLastName",
                            label: "นามสกุล",
                            rules: [{ required: true, message: "กรุณากรอกนามสกุล" }],
                            placeholder: "กรุณากรอกนามสกุล",
                        },
                    ]}
                />
                <RowGroupInput
                    inputs={[
                        {
                            name: "relativePhoneNumber",
                            label: "เบอร์โทรศัพท์",
                            rules: [
                                {
                                    required: true,
                                    message: "กรุณากรอกเบอร์โทรศัพท์",
                                },
                                {
                                    pattern: /^[0-9]*$/,
                                    message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
                                },
                            ],
                            placeholder: "กรุณากรอกเบอร์โทรศัพท์",
                        },
                    ]}
                />

                {xs ? (
                    <RowGroupInput
                        inputs={[
                            {
                                name: "date",
                                label: "วันที่ลงทะเบียน ",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกวันเริ่ม/สิ้นสุด สัญญา",
                                    },
                                ],
                                customInput: (
                                    <RangePicker
                                        style={{ width: "100%", margin: "0 auto" }}
                                        placeholder={["วันที่เริ่ม", "วันที่สิ้นสุด"]}
                                    />
                                ),
                            },
                        ]}
                    />
                ) : (
                    <RowGroupInput
                        inputs={[
                            {
                                name: "date",
                                label: "วันที่ลงทะเบียน ",
                                rules: [
                                    {
                                        required: true,
                                        message: "กรุณาเลือกวันเริ่ม/สิ้นสุด สัญญา",
                                    },
                                ],
                                customInput: (
                                    <RangePicker
                                        style={{ width: "50%", margin: "0 auto" }}
                                        placeholder={["วันที่เริ่ม", "วันที่สิ้นสุด"]}
                                    />
                                ),
                            },
                        ]}
                    />
                )}

                {/* Footer */}
                <Row justify="center">
                    <Button type="primary" htmlType="submit" size={"large"} onClick={onClickSubmit}>
                        บันทึกการลงทะเบียน
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default RegisterBoothShirt;
