import axios from "axios";

export const getQRRegisterLine = async (boothNumber: string) => {
    const response = await axios.get(`/lineNoti/${boothNumber}`);
    return response.data;
};

export const postRegisterLine = async (boothNumber: string, accessToken: string | null) => {
    const response = await axios.post(`/lineNoti/register`, {
        boothNumber,
        accessToken,
    });
    return response.data;
};
