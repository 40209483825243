import { message } from "antd";
import dayjs from "dayjs";
import React from "react";
import { createContract, getBuildingMonthly, getBuildingVeggie } from "../../apis/register";
import { base64ToBlob } from "../../functions/files";
import { uploadImageReturnUrl } from "../../functions/storage";
import { MemberType, BoothType, BuildingType, VeggieType } from "../../interfaces/Register";

export const RegisterContext = React.createContext({} as ReturnType<typeof useRegister>);
export const useRegisterContext = () => React.useContext(RegisterContext);

function useRegister() {
    const [index, setIndex] = React.useState(0);
    const [tenantForm, setTenantForm] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [buildingStorage, setBuildingStorage] = React.useState<BuildingType>({});
    const [boothForm, setBoothForm] = React.useState<any>();
    const [typeForm, setTypeForm] = React.useState<string>("booth");
    const [resultId, setResultId] = React.useState<string>("");
    const nextStep = () => setIndex((index) => index + 1);
    const prevStep = () => setIndex((index) => index - 1);

    const getBuildingStorage = React.useCallback(async (type: "booth" | "veggie") => {
        setIsLoading(true);
        try {
            const buildingStorage =
                type === "booth" ? await getBuildingMonthly() : await getBuildingVeggie();
            setBuildingStorage(buildingStorage);
        } catch (error) {
            console.log({ error });
            message.error("การเชื่อมต่อล้มเหลว");
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getBoothName = React.useCallback(
        (boothCode: string) => {
            const keys = Object.keys(buildingStorage);
            const booth: { [keys: string]: string } = {};
            keys.forEach((item) => {
                buildingStorage[item].map((i) => (booth[i.boothCode] = i.boothName));
            });
            return booth[boothCode];
        },
        [buildingStorage]
    );

    const getUrlImagePicturePathAndSignContract = React.useCallback(
        async (firstSignature: Blob | undefined) => {
            if (!tenantForm.imageBase64) return message.error("กรุณาใส่รูปภาพ");
            if (!firstSignature) return message.error("กรุณาลงลายมือชื่อ");
            const image = await base64ToBlob(tenantForm.imageBase64);
            try {
                const picturePaths = (await uploadImageReturnUrl("images/tenant", [
                    image,
                ])) as string[];
                const signaturePath = (await uploadImageReturnUrl("images/tenant", [
                    firstSignature,
                ])) as string[];
                return { picturePath: picturePaths[0], signaturePath: signaturePath[0] };
            } catch (error) {
                console.log({ error });
                setIsLoading(false);
                return message.error("กรุณาลองใหม่อีกครั้ง");
            }
        },
        [tenantForm]
    );

    const getMembers = (tenantForm: any) =>
        Array.from(Array(tenantForm.numMembers).keys()).map(
            (num) =>
                ({
                    firstName: tenantForm[`firstName${num}`],
                    lastName: tenantForm[`lastName${num}`],
                    ssn: tenantForm[`ssn${num}`],
                    tel: tenantForm[`phoneNumber${num}`],
                    status: "active",
                    address: {
                        address: tenantForm[`address${num}`],
                        province: tenantForm[`province${num}`],
                        district: tenantForm[`district${num}`],
                        subDistrict: tenantForm[`subDistrict${num}`],
                        postCode: tenantForm[`zipCode${num}`],
                    },
                    vaccine: tenantForm[`numVaccines${num}`]
                        ? Array.from(Array(tenantForm[`numVaccines${num}`]).keys()).map(
                              (numVaccine) => {
                                  return {
                                      name: tenantForm[`vaccine${num}${numVaccine}`],
                                      date: +dayjs(tenantForm[`vaccineDate${num}${numVaccine}`]),
                                  };
                              }
                          )
                        : [],
                } as MemberType)
        );

    const onSubmitApplication = React.useCallback(
        async (firstSignature: Blob | undefined) => {
            console.log({ firstSignature });
            console.log({ tenantForm, boothForm });
            console.log(tenantForm.numMembers);
            const [tenant, ...member] = getMembers(tenantForm);
            const { picturePath, signaturePath } = await getUrlImagePicturePathAndSignContract(
                firstSignature
            );
            if (!boothForm?.date[0] && !boothForm?.date[1]) {
                message.error("กรุณาเลือกวันที่เริ่มต้นและสิ้นสุด");
                throw new Error("กรุณาเลือกวันที่เริ่มต้นและสิ้นสุด");
            }

            const config = {
                picturePath,
                signaturePath,
                booth: boothForm.booth,
                parking:
                    boothForm.numOfParking === undefined || boothForm.numOfParking === null
                        ? 1
                        : +boothForm.numOfParking,
                startDate: +dayjs(boothForm.date[0]),
                endDate: +dayjs(boothForm.date[1]),
            };

            let contract: BoothType | VeggieType;
            if (typeForm === "booth") {
                if (!tenantForm.numMembers) {
                    message.error("มีบางอย่างผิดพลาด");
                    throw new Error("มีบางอย่างผิดพลาด");
                }
                contract = {
                    ...tenant,
                    ...config,
                    type: "booth",
                    laborShirt: tenantForm.numMembers - 1,
                    member,
                } as BoothType;
            } else if (typeForm === "veggie") {
                contract = {
                    ...tenant,
                    ...config,
                    involved: {
                        firstName: tenantForm.firstname,
                        lastName: tenantForm.lastname,
                        tel: tenantForm.phoneNumber,
                    },
                    type: "veggie",
                } as VeggieType;
            } else {
                message.error("มีบางอย่างผิดพลาด");
                throw new Error("มีบางอย่างผิดพลาด");
            }

            console.log({ contract });

            try {
                const { documentID } = await createContract(contract);
                setResultId(documentID);
            } catch (error) {
                setIsLoading(false);
                message.error("กรุณาลองใหม่อีกครั้ง");
                throw new Error("กรุณาลองใหม่อีกครั้ง");
            }
            setIsLoading(false);
        },
        [tenantForm, boothForm, typeForm, getUrlImagePicturePathAndSignContract]
    );

    return {
        index,
        setIndex,
        nextStep,
        prevStep,
        tenantForm,
        setTenantForm,
        buildingStorage,
        setBoothForm,
        boothForm,
        isLoading,
        getBuildingStorage,
        onSubmitApplication,
        setTypeForm,
        typeForm,
        resultId,
        getBoothName,
    };
}

export default useRegister;
