import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { WorkManagementCardType } from "../../../../interfaces/MemberManagement";
import WorkManagementCardList from "../WorkManagementCardList";
import { AsideContainer, Container, ContentContainer } from "./index.style";

const WorkManagementCard: React.FC<WorkManagementCardType> = (props) => {
    return (
        <Container>
            <AsideContainer>
                <h3>{props.title}</h3>
                <Avatar size={80} src={props.icon} style={{ backgroundColor: "#FFF" }} />
            </AsideContainer>
            <ContentContainer>
                {props.list.map((item, k) => (
                    <WorkManagementCardList key={k} {...item} />
                ))}
            </ContentContainer>
        </Container>
    );
};

export default WorkManagementCard;
