import React from "react";
import styled from "styled-components";
import colors from "../../../assets/colors.json";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

type BreadcrumbProps = {
    labels: string[];
    onClick: (index: string) => void;
};

export const Container = styled.div`
    display: flex;
    h2:not(:last-child):after {
        padding: 0 0.4rem;
        content: ">";
    }
    h2:last-child {
        color: ${colors.primary};
    }
`;

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
    const { lg } = useBreakpoint();
    const { labels, onClick } = props;
    return (
        <Container>
            {labels.map((label, index) => {
                return (
                    <>
                        {lg ? (
                            label !== "" ? (
                                <h2>{label}</h2>
                            ) : (
                                <></>
                            )
                        ) : label !== "" ? (
                            <h2
                                onClick={() => {
                                    onClick(`${index}`);
                                }}
                            >
                                {label}
                            </h2>
                        ) : (
                            <></>
                        )}
                    </>
                );
            })}
        </Container>
    );
};

export default Breadcrumb;
