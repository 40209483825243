import styled from "styled-components";
import colors from "../../../assets/colors.json";

type IBackground = {
    state: string;
};

export const Container = styled.div`
    display: flex;
    width: 200px;
    flex-direction: column;
    padding: 1.5rem 1rem;
    margin: 0 2rem 0 0;
    align-items: center;
    border: 1px solid ${colors.border};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: ${(props: IBackground) =>
        props.state === "active" ? colors.memberDetailWorkColor.mainDuty : "#FFF"};

    @media screen and (max-width: 768px) {
        margin: 0.5rem 0;
    }
`;

export const Typography = styled.div`
    font-size: 1rem;
    margin-top: 0.5rem;
    font-weight: bold;
    color: ${colors.textColor};
`;
