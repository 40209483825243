import { Container, ContentBackground, } from "../../../../components/common/CustomForm";
import RegisterLomSak from "../../../../components/RegisterPage/Car/LomSak"

function RegisterLomsakPage() {
    return (
        <Container>
            <ContentBackground>
                <h1>ลงทะเบียนรถหล่ม</h1>
                <RegisterLomSak />

            </ContentBackground>
        </Container>
    );
}

export default RegisterLomsakPage;