import { Container, Modal } from "./index.styles";
import VaccineTimeline from "../ModalComponents/VaccineTimeline";
import FooterModal from "../ModalComponents/FooterModal";
import TitleModal from "../ModalComponents/TitleModal";
import FormContent, { FormInstanceType } from "../ModalComponents/FormContent";
import PersonRelated from "../ModalComponents/PersonRelated";
import { IContractResponse } from "../PartnerContractTable/useContractTable";
import { IBoothMember } from "../../../../interfaces/Contract";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

type IModal = {
    visible: boolean;
    visibleInner: boolean;
    onCancel: () => void;
    onOk: () => void;
    onEdit: () => void;
    onCancelEdit: () => void;
    editable: boolean;
    onPrintData: () => void;
    record: IContractResponse | IBoothMember;
    type: FormInstanceType;
};

function ContractInformationModal(props: IModal) {
    const {
        visible,
        visibleInner,
        onCancel,
        onOk,
        record,
        editable,
        type,
        onEdit,
        onCancelEdit,
        onPrintData,
    } = props;

    const includeItem: FormInstanceType[] = ["rider", "labor", "lomsak"];
    const {xs} = useBreakpoint();

    return (
        <Modal
            centered
            closable={false}
            visible={visible || visibleInner}
            onCancel={onCancel}
            onOk={onOk}
            width={xs ? 450 : 550}
            title={<TitleModal record={record} onPrintData={onPrintData} />}
            footer={
                <FooterModal
                    onOk={onOk}
                    onClose={onCancel}
                    onEdit={onOk}
                    onCancelEdit={onCancelEdit}
                    edit={editable}
                />
            }
        >
            <Container>
                <FormContent type={!visibleInner ? type : "inner"} editState={editable} />
                {includeItem.includes(type) && (
                    <PersonRelated edit={editable} />
                )}
                <VaccineTimeline vaccine={record?.vaccine} onEdit={onEdit} edit={editable} />
            </Container>
        </Modal>
    );
}

export default ContractInformationModal;
