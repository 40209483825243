export const rolesThToEn: { [key: string]: string } = {
    เก็บค่าเสื้อขึ้นของ: "laborShirt",
    เก็บค่าเสื้อวิน: "riderShirt",
    เก็บค่าที่จอดรถ: "parking",
    เก็บค่ารถหล่ม: "lomsak",
    เก็บค่าแผงรายเดือน: "booth",
    เก็บค่าแผงผักบ้าน: "veggie",
    เก็บค่าเสื้อแผง: "boothShirt",
    "จดมิเตอร์น้ำ/ไฟ แผงรายเดือน": "meterBooth",
    "จดมิเตอร์น้ำ/ไฟ แผงผักบ้าน": "meterVeggie",
};
export const rolesEnToTh: { [key: string]: string } = {
    laborShirt: "เก็บค่าเสื้อขึ้นของ",
    riderShirt: "เก็บค่าเสื้อวิน",
    parking: "เก็บค่าที่จอดรถ",
    lomsak: "เก็บค่ารถหล่ม",
    booth: "เก็บค่าแผงรายเดือน",
    veggie: "เก็บค่าแผงผักบ้าน",
    boothShirt: "เก็บค่าเสื้แผง",
    meterBooth: "จดมิเตอร์น้ำ/ไฟ แผงรายเดือน",
    meterVeggie: "จดมิเตอร์น้ำ/ไฟ แผงผักบ้าน",
};
export const roleList = { ...rolesEnToTh, ...rolesThToEn };

export const roleArray = [
    "laborShirt",
    "riderShirt",
    "parking",
    "lomsak",
    "booth",
    "veggie",
    "boothShirt",
    "meterBooth",
    "meterVeggie",
];
