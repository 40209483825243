import styled from "styled-components";
import breakpoint from "../../../../assets/breakpoint.json";
import { EditFilled } from "@ant-design/icons";
import colors from "../../../../assets/colors.json";
export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 0.5rem;
    width: calc(1 / 3 * 100% - 1rem);
    overflow: hidden;
    @media (max-width: ${breakpoint.tablet}) {
        width: calc(50% - 1rem);
    }
    @media (max-width: ${breakpoint.mobile}) {
        width: 100%;
    }

    & > h3,
    & > p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }

    & > h3 {
        margin: 1rem 0 0 0;
    }

    & > div {
        width: fit-content;
        text-align: center;
        & span {
            margin: 2px 4px;
        }
    }
`;

export const EditButton = styled.button.attrs({
    children: <EditFilled />,
})`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    background-color: ${colors.background};
`;
