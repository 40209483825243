import { message } from "antd";
import React from "react";
import { getRandomAssignValue, updateValueRandom } from "../../../../apis/member";
import mock from "./mock";

export const workManagementContext = React.createContext(
    {} as ReturnType<typeof useWorkManagement>
);
export const useWorkManagementContext = () => React.useContext(workManagementContext);

function useWorkManagement() {
    const [data, setData] = React.useState(mock);
    const [initVal, setInitVal] = React.useState<{ [key: string]: number }>({});
    const [fething, setFething] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const getValue = React.useCallback(async () => {
        setFething(true);
        const result = await getRandomAssignValue();
        const formInit: { [key: string]: number } = {};
        const resData = result.data;
        Object.keys(resData).forEach((key) => {
            const k = key as keyof typeof resData;
            formInit[k] = resData[k].currValue || 0;
        });
        setInitVal(formInit);
        setData(result.temp);
        setFething(false);
    }, []);
    const onUpdateRandomAssignValue = React.useCallback(async (data: { [key: string]: number }) => {
        await updateValueRandom(data);
    }, []);
    React.useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            try {
                await getValue();
            } catch (error) {
                message.error("มีบางอย่างผิดพลาด");
            }
            setIsLoading(false);
        };
        fetch();
        return () => {
            fetch();
        };
    }, [getValue]);

    return { getValue, fething, data, isLoading, setIsLoading, onUpdateRandomAssignValue, initVal };
}

export default useWorkManagement;
