import styled from "styled-components";
import colors from "../../../../assets/colors.json";
import breakpoint from "../../../../assets/breakpoint.json";

export const Container = styled.div`
    display: flex;
    flex: 1;
    margin: 0.75rem 1rem;
    background-color: #fff;
    @media (max-width: ${breakpoint.mobile}) {
        flex-direction: column;
    }
`;

export const AsideContainer = styled.div`
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primary}55;
    padding: 1.5rem 1rem;

    @media (max-width: ${breakpoint.mobile}) {
        width: 100%;
        padding: 0.75rem 1rem;
        align-items: flex-start;
        & .ant-avatar {
            display: none;
        }
        & h3 {
            margin: 0;
            font-weight: bold;
            text-align: left;
        }
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem 2rem 1rem;
`;
