import React from "react";
import { ContainerTable } from "../CardBuildingSelector/index.style";
import { Table, Image, Tabs } from "antd";
import Building from "../../../../src/assets/icons/building.png";
import Market from "../../../../src/assets/icons/market.png";
import { RightOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { IZone } from "../../../pages/PriceManagement/MonthlyBoothManagement/useMonthlyBoothManagement";

interface DataType {
    key: React.Key;
    building: string;
    length: number;
}

type CardBuildingSelectorProps = {
    type?: String;
    data?: IZone[];
    onSelect: (building: string) => void;
    buildingSelect: string;
};

const CardBuildingSelectorObj: React.FC<CardBuildingSelectorProps> = (props) => {
    const { type, data, onSelect, buildingSelect } = props;

    const { lg } = useBreakpoint();
    const { TabPane } = Tabs;

    return (
        <>
            {lg ? (
                <ContainerTable>
                    <Table
                        onRow={(record: DataType) => {
                            return {
                                onClick: () => {
                                    onSelect(record.building);
                                },
                            };
                        }}
                        rowClassName={(record) =>
                            record.building === buildingSelect ? "selected" : ""
                        }
                        dataSource={data}
                        pagination={false}
                        size="middle"
                    >
                        <Table.Column
                            title="อาคาร"
                            dataIndex="building"
                            key="building"
                            render={(building) => (
                                <div>
                                    {type ? (
                                        <Image
                                            src={type === "booth-monthly" ? Building : Market}
                                            width={30}
                                            preview={false}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {building}
                                </div>
                            )}
                        />
                        <Table.Column
                            title="จำนวนแผง"
                            dataIndex="length"
                            key="length"
                            render={(length) => (
                                <div>
                                    <p> {length}</p>

                                    <RightOutlined />
                                </div>
                            )}
                        />
                    </Table>
                </ContainerTable>
            ) : (
                <Tabs
                    defaultActiveKey={buildingSelect}
                    onChange={(key) => {
                        onSelect(key);
                    }}
                >
                    {data?.map(({ building, length }, index) => (
                        <TabPane tab={building} key={building}>
                            <p>จำนวนทั้งหมด &emsp; {length}</p>
                        </TabPane>
                    ))}
                </Tabs>
            )}
        </>
    );
};

export default CardBuildingSelectorObj;
