import React from "react";
import { MemberType } from "../../../../interfaces/MemberManagement";
import colors from "../../../../assets/colors.json";
import { getAllMember, patchUpdateDutyMember } from "../../../../apis/member";
import { message } from "antd";

export const listColorDetail = [
    {
        title: "หน้าที่หลัก",
        key: "mainDuty",
        color: colors.memberDetailWorkColor.mainDuty,
    },
    {
        title: "หน้าที่ถูกสุ่ม",
        key: "subDuty",
        color: colors.memberDetailWorkColor.subDuty,
    },
    {
        title: "หน้าที่สามารถทำได้",
        key: "dutyPool",
        color: colors.memberDetailWorkColor.dutyPool,
    },
];

export const MemberDetailContext = React.createContext({} as ReturnType<typeof useMemberDetail>);
export const useMemberDetailContext = () => React.useContext(MemberDetailContext);

function useMemberDetail() {
    const [memberList, setMemberList] = React.useState<MemberType[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [memberUpdate, setMemberUpdate] = React.useState<MemberType>();
    const [openModalUpdate, setOpenModalUpdate] = React.useState(false);

    const getMemberList = React.useCallback(async () => {
        try {
            const members = await getAllMember();
            setMemberList(members);
        } catch (error) {
            console.log({ error });
            message.error("มีบางอย่างผิดพลาด");
        }
    }, []);
    const updateDutyMember = React.useCallback(async (member: MemberType) => {
        try {
            await patchUpdateDutyMember(member.uid, member.mainDuty, member.dutyPool);
        } catch (error) {
            console.log({ error });
            message.error("มีบางอย่างผิดพลาด");
        }
    }, []);
    React.useEffect(() => {
        const fecth = async () => {
            setIsLoading(true);
            await getMemberList();
            setIsLoading(false);
        };
        fecth();
        return () => {
            fecth();
        };
    }, [getMemberList]);

    const handleUpdateWorkList = (member: MemberType, mainDuty?: string, dutyPoll?: string[]) => {
        setMemberList((memberAllList) =>
            memberAllList.map((m) => {
                if (m.email === member.email) {
                    m.mainDuty = mainDuty;
                    m.dutyPool = dutyPoll;
                }
                return m;
            })
        );
    };

    const handleSearch = React.useCallback(
        (search: string, list: MemberType[]) =>
            search === ""
                ? list
                : list.filter(
                      (item) =>
                          item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                          item.email.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                  ),
        []
    );

    return {
        handleSearch,
        memberList,
        handleUpdateWorkList,
        setMemberUpdate,
        memberUpdate,
        setOpenModalUpdate,
        openModalUpdate,
        isLoading,
        setIsLoading,
        updateDutyMember,
    };
}

export default useMemberDetail;
