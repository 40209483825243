import { Container } from "../index.styles";
import LayoutContract from "../../../components/common/LayoutContract";
import React from "react";
import { Row, Col } from "antd";
// import ModalCardManage from "../../../components/PriceManagementPage/ModalCardManage/index";
import useMonthlyBoothManagement from "./useMonthlyBoothManagement";
import CardBuildingSelectorObj from "../../../components/common/CardBuildingSelectorObj";

// type mockData = {
//     type: String;
//     dataTable: {
//         key?: string;
//         building: string;
//         rent: number;
//         available: number;
//     }[];
// }[];

function MonthlyBoothManagement() {
    // const [isModalVisible, setIsModalVisible] = React.useState(false);
    // const showModal = () => setIsModalVisible(true);
    // const handleOk = () => setIsModalVisible(false);
    // const handleCancel = () => setIsModalVisible(false);
    const { data, isLoading, tabBuilding, firstKey, setFirstKey } = useMonthlyBoothManagement();
    // const [form] = Form.useForm();

    const [value, setValue] = React.useState<any>();
    const handleValue = () => {
        console.log(value);
    };
    return (
        <Container style={{ flexDirection: "row" }}>
            {!isLoading && (
                <>
                    <Row justify="space-between">
                        <Col xs={24} sm={24} lg={9}>
                            <CardBuildingSelectorObj
                                type="booth-monthly"
                                data={tabBuilding}
                                onSelect={setFirstKey}
                                buildingSelect={firstKey}
                            />
                        </Col>
                        <Col xs={24} sm={24} lg={14}>
                            <LayoutContract
                                data={firstKey ? data[firstKey] : []}
                                keyName="boothName"
                                pageType="booth"
                                trigger={handleValue}
                                getData={setValue}
                                disableStatus={true}
                            />
                        </Col>
                        {/* <ModalCardManage
                            visible={isModalVisible}
                            boothList={dataTable}
                            handleOk={handleOk}
                            handleCancel={handleCancel}
                        /> */}
                    </Row>
                </>
            )}
        </Container>
    );
}

export default MonthlyBoothManagement;
