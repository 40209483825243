import React from "react";
import { StyledComponent } from "styled-components";
import { NumberButton, NumberInputContainer, NumberInputContent } from "./index.style";

type PropsType = {
    onChange?: (value: number) => void;
    value?: number;
    initialValue?: number;
    onPlus?: (newValue: number) => void;
    onMinus?: (newValue: number) => void;
    name?: string;
    maxValue?: number;
    minValue?: number;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    disabled?: boolean;
    inputProps?: StyledComponent<
        "input",
        any,
        {
            type: "number";
        },
        "type"
    >;
};

function NumberInput(props: PropsType) {
    const [value, setValue] = React.useState(props.initialValue || 0);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            const newValue = +event.target.value;
            if (props.maxValue !== undefined && newValue > props.maxValue)
                return setValue(props.maxValue);
            if (props.minValue !== undefined && newValue < props.minValue)
                return setValue(props.minValue);
            setValue(newValue);
            props.onChange && props.onChange(newValue);
        },
        [props]
    );

    const onPlus = React.useCallback(
        () =>
            setValue((value) => {
                if (props.maxValue !== undefined && value >= props.maxValue) return value;
                props.onPlus && props.onPlus(value + 1);
                props.onChange && props.onChange(value + 1);
                return value + 1;
            }),

        [props]
    );

    const onMinus = React.useCallback(
        () =>
            setValue((value) => {
                if (props.minValue !== undefined && value <= props.minValue) return value;
                props.onMinus && props.onMinus(value - 1);
                props.onChange && props.onChange(value - 1);
                return value - 1;
            }),
        [props]
    );

    return (
        <NumberInputContainer style={{ ...props.style, ...props.containerStyle }}>
            <NumberButton onClick={onMinus} style={props.buttonStyle}>
                -
            </NumberButton>
            <NumberInputContent
                name={props.name}
                disabled={props.disabled}
                style={props.inputStyle}
                defaultValue={props.initialValue}
                value={props.value ? props.value : value}
                min={props.minValue}
                max={props.maxValue}
                onChange={handleChange}
                {...props.inputProps}
            />
            <NumberButton onClick={onPlus} style={props.buttonStyle}>
                +
            </NumberButton>
        </NumberInputContainer>
    );
}

export default NumberInput;
