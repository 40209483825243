import {
    DesktopOutlined,
    UserOutlined,
    MoneyCollectOutlined,
    DatabaseOutlined,
    TeamOutlined,
    FormOutlined,
    DollarOutlined,
} from "@ant-design/icons";
import LoginPage from "../pages/Login";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import MemberManagementStaff from "../pages/MemberManagement/Staff";
import PriceManagement from "../pages/PriceManagement";
import RegisterMonthly from "../pages/Register/Monthly";
import RegisterVeggie from "../pages/Register/Veggie";
import TrackMeter from "../pages/TrackMeter";
import ContractMemberManagement from "../pages/MemberManagement/ContractMember";
import RegisterLine from "../pages/RegisterLine";
import RegisterRiderShirt from "../pages/Register/Shirt/RiderShirt";
import RegisterBoothShirt from "../pages/Register/Shirt/BoothShirt";
import RegisterLaborShirtPage from "../pages/Register/Shirt/LaborShirt";
import RegisterLomsakPage from "../pages/Register/Car/Lomsak";
import TestQRLine from "../pages/TestQRLine";

export type MenuType = {
    type: "menu" | "listMenu" | "hide";
    path: string;
    icon?: React.ReactNode;
    title: string;
    name: string;
    component?: React.ReactNode;
    isAcceptRoles?: boolean;
    list?: {
        path: string;
        title: string;
        component: React.ReactNode;
        isAcceptRoles?: boolean;
    }[];
    isHideLayout?: true;
};

const AuthMiddleware = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const auth = useAuthContext();
    if (auth.isLoading) return <div>loading...</div>;
    return auth.user ? children : <Navigate to="/login" state={{ from: location }} />;
};

export const converter = (routesList: MenuType[]) => {
    const routes: {
        path: string;
        component: React.ReactNode;
        isHideLayout?: true;
        isAcceptRoles?: boolean;
    }[] = [];
    routesList.forEach((route: MenuType) => {
        if (route.type === "listMenu") {
            route.list?.forEach((list) => {
                routes.push({
                    path: `${route.path}${list.path}`,
                    component: list.component,
                    isAcceptRoles: list.isAcceptRoles,
                });
            });
        } else {
            routes.push({
                path: route.path,
                component: route.component,
                isHideLayout: route.isHideLayout,
                isAcceptRoles: route.isAcceptRoles,
            });
        }
    });
    return routes;
};

export const ROUTES = () => {
    const auth = useAuthContext();
    const rolesUser = auth.user?.roles;
    const acceptRoles = (roles?: string[]) => {
        if (!roles) return true;
        const accept = roles.filter((role) => rolesUser?.includes(role));
        if (accept.length === 0) return false;
        else return true;
    };
    const routes: MenuType[] = [
        {
            type: "listMenu",
            title: "ลงทะเบียน",
            name: "register",
            icon: <UserOutlined />,
            path: "/register",
            isAcceptRoles: acceptRoles(["admin", "staff"]),
            list: [
                {
                    path: "/monthly",
                    title: "แผงรายเดือน",
                    component: (
                        <AuthMiddleware>
                            <RegisterMonthly />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
                {
                    path: "/veggie",
                    title: "แผงผักบ้าน",
                    component: (
                        <AuthMiddleware>
                            <RegisterVeggie />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
                {
                    path: "/riderShirt",
                    title: "เสื้อวิน",
                    component: (
                        <AuthMiddleware>
                            <RegisterRiderShirt />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
                {
                    path: "/laborShirt",
                    title: "เสื้อขึ้นของ",
                    component: (
                        <AuthMiddleware>
                            <RegisterLaborShirtPage />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
                {
                    path: "/boothShirt",
                    title: "เสื้อแผง",
                    component: (
                        <AuthMiddleware>
                            <RegisterBoothShirt />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
             
                {
                    path: "/LomsakCar",
                    title: "รถหล่ม",
                    component: (
                        <AuthMiddleware>
                            <RegisterLomsakPage />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
            ],
        },
        {
            path: "/dashboard",
            icon: <DesktopOutlined />,
            //TODO change to menu
            type: "hide",
            title: "ภาพรวม",
            name: "dashboard",
            component: (
                <AuthMiddleware>
                    <div>ภาพรวม</div>
                </AuthMiddleware>
            ),
        },
        {
            //TODO change to listMenu

            type: "hide",
            icon: <MoneyCollectOutlined />,
            title: "รายการชำระเงิน",
            name: "historyTransaction",
            path: "/historyTransaction",
            list: [
                {
                    path: "/monthly",
                    title: "แผงรายเดือน",
                    component: (
                        <AuthMiddleware>
                            <div>แผงรายเดือน</div>
                        </AuthMiddleware>
                    ),
                },
                {
                    path: "/local",
                    title: "แผงผักบ้าน",
                    component: (
                        <AuthMiddleware>
                            <div>แผงผักบ้าน</div>
                        </AuthMiddleware>
                    ),
                },
              
                {
                    path: "/shirtMotorcycle",
                    title: "เสื้อวิน",
                    component: (
                        <AuthMiddleware>
                            <div>เสื้อวิน</div>
                        </AuthMiddleware>
                    ),
                },
                {
                    path: "/shirtLabor",
                    title: "เสื้อขึ้นของ",
                    component: (
                        <AuthMiddleware>
                            <div>เสื้อขึ้นของ</div>
                        </AuthMiddleware>
                    ),
                },
                {
                    path: "/shirtWorker",
                    title: "เสื้อแผง",
                    component: (
                        <AuthMiddleware>
                            <div>เสื้อแผง</div>
                        </AuthMiddleware>
                    ),
                },
                {
                    path: "/deliveryCar",
                    title: "รถหล่ม",
                    component: (
                        <AuthMiddleware>
                            <div>รถหล่ม</div>
                        </AuthMiddleware>
                    ),
                },
            ],
        },
        {
            path: "/database",
            //TODO change to menu
            type: "hide",
            icon: <DatabaseOutlined />,
            title: "ฐานข้อมูล",
            name: "database",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <div>ฐานข้อมูล</div>
                </AuthMiddleware>
            ),
        },
        {
            path: "/memberManagement",
            type: "listMenu",
            icon: <TeamOutlined />,
            title: "จัดการพนักงาน",
            name: "memberManagement",
            isAcceptRoles: acceptRoles(["admin"]),
            list: [
                {
                    path: "/staff",
                    title: "จัดการพนักงาน",
                    component: (
                        <AuthMiddleware>
                            <MemberManagementStaff />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
                {
                    path: "/booth",
                    title: "จัดการสัญญา",
                    component: (
                        <AuthMiddleware>
                            <ContractMemberManagement />
                        </AuthMiddleware>
                    ),
                    isAcceptRoles: acceptRoles(["admin"]),
                },
            ],
        },
        {
            path: "/customerManagement",
            type: "menu",
            icon: <TeamOutlined />,
            title: "จัดการลูกค้า",
            name: "customerManagement",
            isAcceptRoles: acceptRoles(["staff"]),
            component: (
                <AuthMiddleware>
                    <div>จัดการลูกค้า</div>
                </AuthMiddleware>
            ),
        },
        {
            path: "/trackMeter",
            type: "menu",
            icon: <FormOutlined />,
            title: "จดมิเตอร์น้ำ/ไฟ",
            name: "trackMeter",
            isAcceptRoles: acceptRoles(["staff", "trackMeter"]),
            component: (
                <AuthMiddleware>
                    <TrackMeter />
                </AuthMiddleware>
            ),
        },
        {
            path: "/priceManagement",
            type: "menu",
            icon: <DollarOutlined />,
            title: "จัดการราคา",
            name: "priceManagement",
            isAcceptRoles: acceptRoles(["admin"]),
            component: (
                <AuthMiddleware>
                    <PriceManagement />
                </AuthMiddleware>
            ),
        },
        {
            path: "/registerLine",
            type: "hide",
            title: "RegisterLine",
            name: "RegisterLine",
            component: <RegisterLine />,
        },
        {
            path: "/testQRLine",
            type: "hide",
            title: "testQRLine",
            name: "testQRLine",
            component: <TestQRLine />,
        },
        {
            path: "/hide",
            type: "hide",
            title: "hide",
            name: "hide",
            component: <div>hide</div>,
        },
        {
            path: "/login",
            type: "hide",
            title: "login",
            name: "login",
            component: <LoginPage />,
            isHideLayout: true,
        },
        {
            path: "/",
            type: "hide",
            title: "GotoDashboard",
            name: "GotoDashboard",
            component: (
                <div>
                    GotoDashboard: <a href="/dashboard">link</a>
                </div>
            ),
            isHideLayout: true,
        },
    ];
    return routes;
};
