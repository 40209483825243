import React from "react";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useNavigate } from "react-router-dom";
import { AppMenu, AppSidebar } from "./index.style";
import { LogoutOutlined } from "@ant-design/icons";
import { ROUTES } from "../routes";
import { useAuthContext } from "../../providers/AuthProvider";

function AppSideBarComponent({ collapsed }: { collapsed: boolean }) {
    const navigate = useNavigate();
    const pn = window.location.pathname;
    const pathName = pn[pn.length - 1] === "/" ? pn.slice(0, -1) : pn;
    const auth = useAuthContext();
    const MenuRender = ROUTES().map(({ path, icon, list, type, title, name, isAcceptRoles }) => {
        const isHideMenu =
            type === "listMenu"
                ? !list?.find((item) => item.isAcceptRoles !== false)
                : isAcceptRoles === false;

        if (isHideMenu) return null;

        return type === "menu" ? (
            <Menu.Item key={path} icon={icon} onClick={() => navigate(path)} children={title} />
        ) : type === "listMenu" ? (
            <SubMenu
                key={name}
                icon={icon}
                title={title}
                children={list?.map((subRoute) => {
                    if (subRoute.isAcceptRoles === false) return null;
                    return (
                        <Menu.Item
                            title={subRoute.title}
                            key={`${path}${subRoute.path}`}
                            onClick={() => navigate(`${path}${subRoute.path}`)}
                            children={subRoute.title}
                            icon={<span />}
                        />
                    );
                })}
            />
        ) : null;
    });

    return (
        <AppSidebar className={!collapsed ? "toggle" : ""} collapsed={collapsed}>
            <AppMenu
                theme="light"
                mode="inline"
                defaultSelectedKeys={[pathName]}
                defaultOpenKeys={[pathName.split("/")[1]]}
            >
                {MenuRender}
            </AppMenu>
            <Menu theme="light" mode="inline">
                <Menu.Item key="login-menu" icon={<LogoutOutlined />} onClick={auth.logout}>
                    ออกจากระบบ
                </Menu.Item>
            </Menu>
        </AppSidebar>
    );
}

export default AppSideBarComponent;
