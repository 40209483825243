import { Button, Modal, Select } from "antd";
import styled from "styled-components";
import colors from "../../../../assets/colors.json";

export const CustomModal = styled(Modal)`
    & .ant-modal-content {
        border-radius: 16px;
    }
    & .ant-modal-footer {
        border: none;
    }
`;

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h3 {
        margin: 1rem 0 0 0;
    }
`;

export const SelectorContainer = styled.div`
    width: 100%;
    & > p {
        margin: 1rem 0 0 0;
    }
`;

export const MaxSelectorHint = styled.p`
    width: 100%;
    text-align: right;

    & span {
        color: ${colors.primary};
    }
`;

export const SubmitButton = styled(Button).attrs({
    type: "primary",
})`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 8px;
`;

export const NoOptions = styled.span`
    &::before {
        content: "ไม่พบตัวเลือก";
        color: ${colors.hintText};
    }
`;

export const CustomSelect = styled(Select)`
    width: 100%;
`;
