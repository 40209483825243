import { Collapse, PageHeader, Form, FormInstance, Spin } from "antd";
import { Container, ContainerSpinner } from "../index.styles";
import { FormMeterRenderer } from "../../../components/PriceManagementPage/MeterManagement/FormMeterRendererArray";
import RenderPanelData from "../../../components/PriceManagementPage/RenderPanelData";
import { Renderer } from "../../../interfaces/PriceManagement";
import useMeterManagement from "./useMeterManagement";

function MeterManagement() {
    const { data, form, isLoading, onFinish, onResetForm } = useMeterManagement();

    const onRendererPanel = FormMeterRenderer.map((item: Renderer, index: number) => (
        <Collapse.Panel header={item.header} key={index}>
            <RenderPanelData component={item.component} cancel={onResetForm} />
        </Collapse.Panel>
    ));

    return (
        <Container>
            <PageHeader title="จัดการราคามิเตอร์ไฟและมิเตอร์น้ำ" />
            {isLoading ? (
                <ContainerSpinner>
                    <Spin tip="กำลังรีเฟรชข้อมูล..." />
                </ContainerSpinner>
            ) : (
                <Form
                    initialValues={data}
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    ref={(formRef: FormInstance) => formRef}
                    role="form"
                >
                    <Collapse accordion onChange={onResetForm}>
                        {onRendererPanel}
                    </Collapse>
                </Form>
            )}
        </Container>
    );
}

export default MeterManagement;
