import React from "react";
import { FormItem, InputContainer, InputItem, TextAreaInput, TextPara } from "./index.styles";

export type FormInstanceType = "booth" | "veggie" | "rider" | "member" | "labor" | "lomsak";
type IFormContentProps = {
    type: Omit<FormInstanceType, "inner">;
    editState: boolean;
};

type FormContentItemProp = {
    type: Omit<FormInstanceType, "inner">[];
    title: string;
    name: string;
    editable?: boolean;
};

const FormContentItem: FormContentItemProp[] = [
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak"],
        title: "เลขบัตรประจำตัวประชาชน",
        name: "ssn",
    },
    {
        type: ["inner"],
        title: "เลขบัตรประจำตัวประชาชน",
        name: "ssn",
        editable: true,
    },
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak", "inner"],
        title: "เบอร์โทรศัพท์ที่ติดต่อได้",
        name: "tel",
        editable: true,
    },
    {
        type: ["boothShirt", "labor"],
        title: "เลขเสื้อ",
        name: "shirtNumber",
    },
    {
        type: ["lomsak"],
        title: "ประเภทรถ",
        name: "size",
    },
    {
        type: ["lomsak"],
        title: "ช่องจอดรถ",
        name: "parkingNumber",
    },
    {
        type: ["booth", "veggie", "labor", "lomsak"],
        title: "รหัสประจำแผง",
        name: "booth",
    },
    {
        type: ["booth", "veggie", "rider", "labor", "lomsak", "inner"],
        title: "ที่อยู่ปัจจุบัน",
        name: "address",
    },
];

function FormContent(props: IFormContentProps) {
    const { type, editState } = props;
    return (
        <>
            {FormContentItem.map((item, index) => {
                return item.type.includes(type) ? (
                    <InputContainer key={index}>
                        <TextPara strong>{item.title}</TextPara>
                        {item.name === "address" ? (
                            <>
                                <FormItem name={item.name}>
                                    {item.editable ? (
                                        <TextAreaInput
                                            readOnly={!editState}
                                            editable={`${editState}`}
                                            autoSize={{ maxRows: 5 }}
                                        />
                                    ) : (
                                        <TextAreaInput readOnly autoSize={{ maxRows: 5 }} />
                                    )}
                                </FormItem>
                            </>
                        ) : (
                            <>
                                <FormItem name={item.name}>
                                    {item.editable ? (
                                        <InputItem
                                            readOnly={!editState}
                                            editable={`${editState}`}
                                        />
                                    ) : (
                                        <InputItem readOnly />
                                    )}
                                </FormItem>
                            </>
                        )}
                    </InputContainer>
                ) : (
                    <React.Fragment key={index}></React.Fragment>
                );
            })}
        </>
    );
}
export default FormContent;
