import { PageHeader, Tabs } from "antd";
import { Container, Content, HeaderContainer, TabTitle } from "./index.styles";
import React from "react";
import PartnerContractTable from "../../../components/MemberManagementPage/ContractMember/PartnerContractTable";

type SubRouteProps = {
    title: string | React.ReactNode;
    component: React.ReactNode;
};

function ContractMemberManagement() {
   
    const ContractMemberManagementSubRoute: SubRouteProps[] = [
        {
            title: <TabTitle>แผงรายเดือน</TabTitle>,
            component: <PartnerContractTable type="booth" />,
        },
        {
            title: <TabTitle>แผงผักบ้าน</TabTitle>,
            component: <PartnerContractTable type="veggie" />,
        },
        {
            title: <TabTitle>เสื้อวิน</TabTitle>,
            component: <PartnerContractTable type="rider" />,
        },
        {
            title: <TabTitle>เสื้อขึ้นของ</TabTitle>,
            component: <PartnerContractTable type="labor" />,
        },
        {
            title: <TabTitle>รถหล่ม</TabTitle>,
            component: <PartnerContractTable type="lomsak" />,
        },
    ];

    return (
        <Container>
            <HeaderContainer>
                <PageHeader title="จัดการสมาชิกแผง" />
            </HeaderContainer>
            <Content>
                <Tabs defaultActiveKey="1">
                    {ContractMemberManagementSubRoute.map((sRoute, index) => (
                        <Tabs.TabPane tab={sRoute.title} key={`${index + 1}`}>
                            {sRoute.component}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Content>
        </Container>
    );
}

export default ContractMemberManagement;
