import styled from "styled-components";
import { Layout, Menu } from "antd";
export const { Header, Sider, Content } = Layout;

export const AppSidebar = styled(Sider).attrs({
    trigger: null,
    collapsible: true,
})`
    background-color: #fff;
    @media (max-width: 767px) {
        transition: all 0.5s;
        display: none;
        &.toggle {
            transition: all 0.5s;
            display: block;
        }
    }
`;

export const AppMenu = styled(Menu)`
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    height: calc(100% - 50px);
`;
