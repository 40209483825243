import { Button, Row, Form, notification } from "antd";
import React from "react";
import { Container } from "../../../common/CustomForm";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import MemberForm from "../../common/MemberForm";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { antdFileToBase64 } from "../../../../functions/files";
import { CustomUpload } from "./index.style";
import useRegister from "../../../../pages/Register/useRegister";

const RegisterMonthlyTenantForm: React.FC<ReturnType<typeof useRegister>> = ({
    nextStep,
    tenantForm,
    setTenantForm,
}) => {
    const [numMembers, setNumMembers] = React.useState<number>(tenantForm?.numMembers || 2);
    const [imageBase64, setImageBase64] = React.useState(tenantForm?.imageBase64);
    const [loadingImage, setLoadingImage] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<string>();
    const [form] = Form.useForm();
    const popMember = () => setNumMembers((val) => val - 1);

    const onFinish = (values: any) => {
        // Validate Image
        if (!imageBase64) return;
        setTenantForm({ ...values, numMembers, imageBase64 });
        console.log({ ...values, numMembers, imageBase64 });
        nextStep();
    };

    const onClickSubmit = () => {
        if (!imageBase64) {
            setImageError("กรุณาอัพโหลดรูปผู้เช่า");
            notification.error({ message: "กรุณาอัพโหลดรูปผู้เช่า" });
        }
    };

    const onChangeImage = React.useCallback(async (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setImageBase64(undefined);
            setImageError(undefined);
            return setLoadingImage(true);
        } else {
            const src = await antdFileToBase64(info.file);
            if (!src?.includes("data:image")) {
                setImageError("กรุณาอัพโหลดไฟล์รูป");
                notification.error({ message: "กรุณาอัพโหลดไฟล์รูป" });
            } else setImageBase64(src);
        }

        setLoadingImage(false);
    }, []);

    const uploadButton = React.useMemo(
        () => (
            <div>
                {loadingImage ? (
                    <LoadingOutlined />
                ) : (
                    <PlusOutlined style={{ color: imageError ? "red" : "black" }} />
                )}
                <div style={{ marginTop: 8, color: imageError ? "red" : "black" }}>
                    {imageError || "เพิ่มรูป"}
                </div>
            </div>
        ),
        [loadingImage, imageError]
    );

    return (
        <Container>
            <Form form={form} initialValues={tenantForm} onFinish={onFinish}>
                {/* Form ของผู้เช้าแผง  */}
                <Row justify="center">
                    <h2>ข้อมูลผู้เช่า</h2>
                </Row>
                <Row justify="center">
                    <CustomUpload
                        error={imageError}
                        name="รูปผู้เช่า"
                        listType="picture-card"
                        showUploadList={false}
                        onChange={onChangeImage}
                    >
                        {imageBase64 ? (
                            <img src={imageBase64} alt="avatar" style={{ width: "100%" }} />
                        ) : (
                            uploadButton
                        )}
                    </CustomUpload>
                </Row>
                <MemberForm
                    key={0}
                    memIndex={0}
                    numMembers={numMembers}
                    form={form}
                    popMember={popMember}
                />
                <hr />
                {/* Form ของ สมาชิกแผง */}
                <Row justify="center">
                    <h2>ข้อมูลสมาชิก</h2>
                </Row>
                <Collapse
                    style={{ backgroundColor: "transparent", padding: "0", margin: "0" }}
                    defaultActiveKey={["1"]}
                    bordered={false}
                    onChange={() => {}}
                >
                    {Array.from(Array(numMembers).keys()).map(
                        (memIndex) =>
                            memIndex > 0 && (
                                <Collapse.Panel
                                    key={memIndex}
                                    header={"ข้อมูลสมาชิกคนที่ " + memIndex}
                                    extra={
                                        memIndex > 1 &&
                                        memIndex === numMembers - 1 && (
                                            <Button
                                                danger
                                                icon={<MinusCircleOutlined />}
                                                size={"small"}
                                                onClick={popMember}
                                            >
                                                ลบสมาชิก
                                            </Button>
                                        )
                                    }
                                >
                                    <MemberForm
                                        memIndex={memIndex}
                                        numMembers={numMembers}
                                        form={form}
                                        popMember={popMember}
                                    />
                                </Collapse.Panel>
                            )
                    )}
                </Collapse>
                {/* Add member button */}
                <Row justify="center" style={{ margin: "1rem" }}>
                    <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => setNumMembers((num) => num + 1)}
                    >
                        เพิ่มสมาชิก
                    </Button>
                </Row>
                {/* Footer */}
                <Row justify="space-between">
                    <Button disabled>ย้อนกลับ</Button>
                    <Button type="primary" onClick={onClickSubmit} htmlType="submit">
                        ถัดไป
                    </Button>
                </Row>
            </Form>
        </Container>
    );
};

export default RegisterMonthlyTenantForm;
