import { Modal as M } from "antd";
import styled from "styled-components";
// import colors from "../../../../../assets/colors.json";

export const Modal = styled(M)`
    &.ant-modal-content {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 1rem;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
    max-height: 400px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
