import axios from "axios";
import { IPricing } from "../interfaces/PriceManagement";

export const getShirtPrices = async () => {
    const res = await axios.get(`/pricingManagement/shirt`);
    return convertKeys(res.data) as IPricing;
};

export const getMeterPrices = async () => {
    const res = await axios.get(`/pricingManagement/meter`);
    return convertKeys(res.data) as IPricing;
};

export const getCarPrices = async () => {
    const res = await axios.get(`/pricingManagement/car`);
    return convertKeys(res.data) as IPricing;
};

export const patchShirtPrices = async (data: any) => {
    const res = await axios.patch(`/pricingManagement/shirt`, data);
    return res.data;
};

export const patchMeterPrices = async (data: any) => {
    const res = await axios.patch(`/pricingManagement/meter`, data);
    return res.data;
};

export const patchCarPrices = async (data: any) => {
    const res = await axios.patch(`/pricingManagement/car`, data);
    return res.data;
};

const convertKeys = (data: any) => {
    const newData: IPricing = {};
    Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((subKey) => {
            newData[`${key}-${subKey}`] = data[key][subKey];
        })
    });
    return newData as IPricing;
};
