import { Rule } from "antd/lib/form";

export default {
    phoneNumber: [
        {
            //TODO Check number phone format
            pattern: /^0[9, 8, 6][0-9]{8}$/,
            message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
        },
    ],
    ssn: [
        {
            //TODO Check ssn format
            pattern: /^[0-9]{13}$/,
            message: "กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง",
        },
    ],
    zipCode: [
        {
            //TODO Check zip code format
            pattern: /^[0-9]{5}$/,
            message: "กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง",
        },
    ],
} as { [key: string]: Rule[] };