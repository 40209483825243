import {
    Container,
    LogoContainer,
    LogoImage,
    BoxContent,
    Title,
    SubTitle,
    BottomBoxContent,
    Text,
} from "./index.styles";
import SMTLogo from "../../assets/images/smt-logo.png";
import GoogleLogin from "../../components/LoginPage/GoogleLogin";
import Footer from "../../components/LoginPage/Footer";
import { useAuthContext } from "../../providers/AuthProvider";

function LoginPage() {
    const auth = useAuthContext();

    const signInWithGoogle = async () => await auth.login();
    return (
        <Container>
            <BoxContent>
                <LogoContainer>
                    <LogoImage src={SMTLogo} alt="smt-logo" />
                </LogoContainer>
                <Title>ยินดีต้อนรับ</Title>
                <SubTitle>ระบบจัดการบัญชีตลาดศรีเมืองทอง</SubTitle>
                <GoogleLogin handleLogin={signInWithGoogle} />
                <BottomBoxContent>
                    <Text>หากมีปัญหาในการเข้าสู่ระบบ</Text>
                    <Text>กรุณาติดต่อผู้ดูแลเพื่อขอสิทธิ์การเข้าถึง</Text>
                </BottomBoxContent>
            </BoxContent>
            <Footer />
        </Container>
    );
}

export default LoginPage;
