import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";

export default styled(Input).attrs({
    prefix: <SearchOutlined />,
})`
    width: 240px;
    color: #d9d9d9;
`;
