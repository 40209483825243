import React from "react";
import { Avatar, Select } from "antd";
import { useMemberDetailContext } from "../../../../pages/MemberManagement/Staff/MemberDetail/useMemberDetail";
import { UserOutlined } from "@ant-design/icons";
import { rolesEnToTh } from "../../../../assets/roles";

import {
    Container,
    CustomModal,
    MaxSelectorHint,
    SelectorContainer,
    SubmitButton,
    CustomSelect,
} from "./index.style";

export interface WorkOption {
    readonly value: string;
    readonly label: string;
}
const { Option } = Select;

const rolesList: string[] = Object.keys(rolesEnToTh);

function ModalUpdateWorkList() {
    const [dutyPool, setWorkList] = React.useState<string[]>();
    const [mainDuty, setMainWork] = React.useState<string>();
    const {
        openModalUpdate,
        memberUpdate,
        setOpenModalUpdate,
        handleUpdateWorkList,
        updateDutyMember,
        setIsLoading,
    } = useMemberDetailContext();

    React.useEffect(() => {
        if (!memberUpdate) return;
        setWorkList(memberUpdate?.dutyPool);
        setMainWork(memberUpdate.mainDuty);
    }, [memberUpdate]);

    React.useEffect(
        () => setWorkList((old) => old?.filter((item) => item !== mainDuty)),
        [mainDuty]
    );

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
        async (event) => {
            event.preventDefault();
            setIsLoading(true);
            if (!memberUpdate) return;
            await updateDutyMember({ ...memberUpdate, mainDuty, dutyPool });
            handleUpdateWorkList(memberUpdate, mainDuty, dutyPool);
            setOpenModalUpdate(false);
            setIsLoading(false);
        },
        [
            memberUpdate,
            mainDuty,
            dutyPool,
            handleUpdateWorkList,
            updateDutyMember,
            setIsLoading,
            setOpenModalUpdate,
        ]
    );

    return (
        <CustomModal
            visible={openModalUpdate}
            footer={<div></div>}
            onCancel={() => setOpenModalUpdate(false)}
        >
            <Container onSubmit={handleSubmit}>
                <Avatar size={96} icon={<UserOutlined />} src={memberUpdate?.urlImage} />
                <h3>{memberUpdate?.name}</h3>
                <p>{memberUpdate?.email}</p>
                <SelectorContainer>
                    <p>หน้าที่หลัก</p>
                    <CustomSelect
                        value={mainDuty}
                        onChange={(value) => setMainWork(value as string)}
                    >
                        {rolesList.map((item) => (
                            <Option key={item} value={item}>
                                {rolesEnToTh[item]}
                            </Option>
                        ))}
                    </CustomSelect>
                </SelectorContainer>
                <SelectorContainer>
                    <p>หน้าที่สามารถทำได้</p>
                    <CustomSelect
                        mode="multiple"
                        value={dutyPool}
                        maxTagCount={4}
                        onChange={(value) => {
                            const val = value as string[];
                            if (val.length > 4) return;
                            setWorkList(val);
                        }}
                    >
                        {rolesList
                            .filter((item) => item !== mainDuty)
                            .map((item) => (
                                <Option key={item} value={item}>
                                    {rolesEnToTh[item]}
                                </Option>
                            ))}
                    </CustomSelect>
                </SelectorContainer>
                <MaxSelectorHint>
                    จำนวนหน้าที่ทั้งหมด <span>({dutyPool?.length} / 4)</span>
                </MaxSelectorHint>
                <SubmitButton htmlType="submit">บันทึก</SubmitButton>
            </Container>
        </CustomModal>
    );
}

export default ModalUpdateWorkList;
