import React from "react";
import { getBuilding } from "../../../apis/zone";
import { catchingError } from "../../../functions/notification";
import { uniqueId } from "../../../functions/uniqueKeys";

export interface IZone {
    key: string;
    building: string;
    length: number;
}

function useMonthlyBoothManagement() {
    const [data, setData] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [tabBuilding, setTabBuilding] = React.useState<IZone[]>();
    const [firstKey, setFirstKey] = React.useState<string>("");

    const fetchData = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const building = await getBuilding("booth");
            const tab: IZone[] = [];
            Object.keys(building).forEach((key) => {
                tab.push({
                    key: uniqueId(),
                    building: key,
                    length: building[key].length,
                });
            });
            setData(building);
            setTabBuilding(tab);
            setFirstKey(tab[0].key);
        } catch (error) {
            catchingError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    React.useEffect(() => {
        fetchData();
        return () => {
            fetchData();
        };
    }, [fetchData]);

    return {
        data,
        tabBuilding,
        isLoading,
        firstKey,
        setFirstKey,
    };
}
export default useMonthlyBoothManagement;
