import React from "react";
import { ConfigProvider } from "antd";
import colors from "../assets/colors.json";

export const ApplicationThemeContext = React.createContext<{
    themeMode: "dark" | "light";
    toggleMode: (value?: "dark" | "light") => void;
}>({
    themeMode: "light",
    toggleMode: () => {},
});

ConfigProvider.config({
    theme: {
        primaryColor: colors.primary,
        warningColor: colors.warning,
        errorColor: colors.error,
        infoColor: colors.info,
    },
});

function ApplicationThemeProvider({ children }: { children: React.ReactNode }) {
    const [themeMode, setThemeMode] = React.useState<"dark" | "light">("light");
    const toggleMode = (value?: "dark" | "light") => {
        if (!value) setThemeMode(themeMode === "light" ? "dark" : "light");
        else setThemeMode(value);
    };
    return (
        <ApplicationThemeContext.Provider value={{ themeMode, toggleMode }}>
            {children}
        </ApplicationThemeContext.Provider>
    );
}

export const useApplicationThemeContext = () => React.useContext(ApplicationThemeContext);

export default ApplicationThemeProvider;
