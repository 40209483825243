import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const uploadImageReturnUrl = async (path: string, imageFile: Blob[], uid?: string) => {
    const urlImage: string[] = [];

    for (let image of imageFile) {
        const storage = getStorage();
        const fileName = `${randomId()}${uid ? `_${uid}` : ""}.${getExtensionFile(image.type)}`;
        const pathImage = `${path}/${fileName}`;
        const storageRef = ref(storage, pathImage);
        //initiates the firebase side uploading
        try {
            await uploadBytes(storageRef, image);
            const url = await getDownloadURL(storageRef);
            urlImage.push(url);
        } catch (error) {
            return error;
        }
    }
    return urlImage;
};

const getExtensionFile = (type: string) => type.split("/")[type.split("/").length - 1];

const randomId = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 30) + Math.random().toString(36).substr(2, 30);
};
